import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./TopupMyAccountsUIHelpers";

const TopupMyAccountsUIContext = createContext();

export function useTopupMyAccountsUIContext() {
  return useContext(TopupMyAccountsUIContext);
}

export const TopupMyAccountsUIConsumer = TopupMyAccountsUIContext.Consumer;

export function TopupMyAccountsUIProvider({ topupMyAccountsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openUpdateTopupMyAccountsStatusDialog:
      topupMyAccountsUIEvents.openUpdateTopupMyAccountsStatusDialog,
    openRateOfferDialog:
      topupMyAccountsUIEvents.openRateOfferDialog,
      openValidity:
      topupMyAccountsUIEvents.openValidity,
      openActivateBestOfferForYou:
      topupMyAccountsUIEvents.openActivateBestOfferForYou,
  };

  return (
    <TopupMyAccountsUIContext.Provider value={value}>
      {children}
    </TopupMyAccountsUIContext.Provider>
  );
}
