import React from "react";
import { Route } from "react-router-dom";
import { ActiveOffersLoadingDialog } from "./activeOffers-loading-dialog/ActiveOffersLoadingDialog";
import { ActiveOffersUpdateStatusDialog } from "./activeOffers-update-status-dialog/ActiveOffersUpdateStatusDialog";
import { ActiveOffersUIProvider } from "./ActiveOffersUIContext";
import { ActiveOffersCard } from "./ActiveOffersCard";

export function ActiveOffersPage({ history }) {
  const activeOffersUIEvents = {
    openUpdateActiveOffersStatusDialog: () => {
      history.push("/my-korek/active-offers/updateStatus");
    },
  };

  return (
    <ActiveOffersUIProvider activeOffersUIEvents={activeOffersUIEvents}>
      <ActiveOffersLoadingDialog />
      <Route path="/my-korek/active-offers/updateStatus">
        {({ history, match }) => (
          <ActiveOffersUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/my-korek/active-offers");
            }}
          />
        )}
      </Route>
      <ActiveOffersCard />
    </ActiveOffersUIProvider>
  );
}
