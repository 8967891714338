/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dropdown, OverlayTrigger, Row, Col, Tooltip, Image, Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../_metronic/_partials/dropdowns";
import { Divider } from "@material-ui/core";
import { Delete, DeleteOutlineRounded } from "@material-ui/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useFormik } from "formik";
import { deactivate } from "../../Auth/_redux/authCrud";
import { FormattedMessage } from "react-intl";
// import * as actions from "../_redux/Profile/profileActions";

export function ProfileCard() {
  // const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const accountItems = useSelector(
    (state) => state.profileSlice.accountItems,
    shallowEqual
  );
  const history = useHistory()
  // useEffect(() => {
  //   return () => {};
  // }, [user]);

  const [show, setShow] = useState()
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  // useEffect(() => {
  //   dispatch(actions.getProfile());
  // }, []);

  // const formik = useFormik({
  //   onSubmit: (values, { setStatus, setSubmitting }) => {
  //     enableLoading();

  //   },
  // });
  const handleSubmit = () => {
    enableLoading();
    setTimeout(() => {
      deactivate()
        .then((data) => {
          if (data.error) {
            disableLoading();
          }
          else {
            disableLoading();
            setShow(false)
            history.push("/logout");
          }
        })
        .catch((data) => {
          // setStatus(data.message);
        })
        .finally(() => {
          disableLoading();
          // history.push("/logout");
          // setSubmitting(false);
        });
    }, 1000);
  };
  return (
    <>
      {user && (
        <div
          className="flex-row-auto"
          id="kt_profile_aside"
        >
          <div className="card card-custom card-stretch">
            {/* begin::Body */}
            <div className="card-body pt-4 pb-0 px-4">
              {/* begin::Toolbar */}
              <div className="d-flex justify-content-end">

              </div>
              {/* end::Toolbar */}
              {/* begin::User */}
              <Row>
                <Col xl={2} className="px-0">
                  <div className="symbol symbol-140 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center mx-0">
                    <div
                      className="symbol-label"
                      style={{ backgroundImage: `url(${user.userProfile.pic != null ? user.userProfile.pic : "/media/users/default.jpg"})` }}
                    ></div>
                    {/* style="background-i
                  mage:url('/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg')" */}
                    {/* <i className="symbol-badge bg-success"></i> */}
                  </div></Col>
                <Col xl={10} className="px-0">
                  <div
                    className="font-weight-bolder account-name"
                  >
                    {user.userProfile.firstName} {user.userProfile.lastName}
                    {/* <span className="px-5" style={{color:"gray",fontSize:"1.5rem"}}>.</span>
                    <span class="svg-icon svg-icon-primary svg-icon-2x">
                     <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"/>
                            <rect fill="#000000" opacity="0.3" transform="translate(13.000000, 6.000000) rotate(-450.000000) translate(-13.000000, -6.000000) " x="12" y="8.8817842e-16" width="2" height="12" rx="1"/>
                            <path d="M9.79289322,3.79289322 C10.1834175,3.40236893 10.8165825,3.40236893 11.2071068,3.79289322 C11.5976311,4.18341751 11.5976311,4.81658249 11.2071068,5.20710678 L8.20710678,8.20710678 C7.81658249,8.59763107 7.18341751,8.59763107 6.79289322,8.20710678 L3.79289322,5.20710678 C3.40236893,4.81658249 3.40236893,4.18341751 3.79289322,3.79289322 C4.18341751,3.40236893 4.81658249,3.40236893 5.20710678,3.79289322 L7.5,6.08578644 L9.79289322,3.79289322 Z" fill="#000000" fill-rule="nonzero" transform="translate(7.500000, 6.000000) rotate(-270.000000) translate(-7.500000, -6.000000) "/>
                            <rect fill="#000000" opacity="0.3" transform="translate(11.000000, 18.000000) scale(1, -1) rotate(90.000000) translate(-11.000000, -18.000000) " x="10" y="12" width="2" height="12" rx="1"/>
                            <path d="M18.7928932,15.7928932 C19.1834175,15.4023689 19.8165825,15.4023689 20.2071068,15.7928932 C20.5976311,16.1834175 20.5976311,16.8165825 20.2071068,17.2071068 L17.2071068,20.2071068 C16.8165825,20.5976311 16.1834175,20.5976311 15.7928932,20.2071068 L12.7928932,17.2071068 C12.4023689,16.8165825 12.4023689,16.1834175 12.7928932,15.7928932 C13.1834175,15.4023689 13.8165825,15.4023689 14.2071068,15.7928932 L16.5,18.0857864 L18.7928932,15.7928932 Z" fill="#000000" fill-rule="nonzero" transform="translate(16.500000, 18.000000) scale(1, -1) rotate(270.000000) translate(-16.500000, -18.000000) "/>
                        </g>
                    </svg></span><span className="swi-acc">Switch Account</span> */}
                  </div>
                  <div className="account-number">{user.userProfile.msisdn}</div>
                  <div>

                    <Row className="mx-auto pt-5 no-wrap" style={{ width: "100%" }}>
                      {
                        accountItems && accountItems.length > 0 && accountItems.map((x, i) => x.idType != 5 && (

                          <Col xl={3} lg={3} md={3} sm={3} xs={6} className="px-2">
                            <Row className="account-info">
                              <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                <Image className="" src={"/media/korek/account-" + x.idType + ".png"}></Image>
                              </Col>{" "}
                              <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                                <span className="upper-title">{x.itemName}</span>
                                <br />
                                <span className="lower-title">{x.itemValue}</span>
                              </Col>
                            </Row>
                          </Col>
                        ))
                      }
                    </Row>
                    <Row className="mt-5">
                      <button
                        onClick={() => setShow(true)}
                        type="button"
                        className="btn btn-light-danger font-weight-bold btn-sm"
                      >
                        <DeleteOutlineRounded /><FormattedMessage id="AUTH.DEACTIVATE_ACCOUNT"></FormattedMessage>
                      </button></Row>
                  </div>
                </Col>
              </Row>
              {/* begin::Nav */}
              <Divider className="mt-5" />
              <div className="navi navi-bold navi-hover navi-active d-flex navi-link-rounded">
                <div className="navi-item">
                  <NavLink
                    to="/my-korek/my-account/my-profile"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-text font-size-lg no-wrap">
                      <FormattedMessage id="AUTH.MY_PROF"></FormattedMessage>
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item">
                  <NavLink
                    to="/my-korek/my-account/controls"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-text font-size-lg">
                      <FormattedMessage id="AUTH.CONTROLS"></FormattedMessage>
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item">
                  <NavLink
                    to="/my-korek/my-account/connected-devices"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-text font-size-lg no-wrap">
                      <FormattedMessage id="AUTH.CONNECTED_DEVICES"></FormattedMessage>
                    </span>
                  </NavLink>
                </div>
                <Link style={{ alignSelf: "center", fontSize: "1.1rem", fontWeight: "400" }} className="mr-0 ml-auto">
                  <div className="navi-item text-danger mr-0 ml-auto">
                  </div></Link>

                {/* <div className="navi-item mb-2">
                  <NavLink
                    to="/my-korek/my-account/change-password"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Shield-user.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Change Password
                    </span>
                    <span className="navi-label">
                      <span className="label label-light-danger label-rounded font-weight-bold">
                        5
                      </span>
                    </span>
                  </NavLink>
                </div> */}
              </div>
              {/* end::Nav */}
            </div>
            {/* end::Body */}
          </div>
        </div>
      )}

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header
          closeButton
        // style={{ borderBottom: "0px" }}
        >
          <Modal.Title
            className="justify-content-start" id="example-modal-sizes-title-lg">

          </Modal.Title></Modal.Header>
        <Modal.Body>
          <div
            // onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            <><FormattedMessage id="DEACTIVATE_ACCOUNT_CONF"></FormattedMessage></>
          </div>
        </Modal.Body>
        <Modal.Footer
          // style={{ borderTop: "0px" }}
          className="justify-content-center"
        >
          <Row className="">
            <Col xl={12} className="text-center">
              <Button
                className="mx-3"
                onClick={() => handleSubmit()}
                id="kt_login_signin_submit"
                variant="warning"
                disabled={loading}
              >
                <b>Deactivate</b>
                {loading && (
                  <span className="mx-3 spinner spinner-white"></span>
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
