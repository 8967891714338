import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

const HomePage = lazy(() =>
  import("./modules/Business/pages/HomePage")
);
const BlueProgramPage = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage")
);
const KorekOffersPage = lazy(() =>
  import("./modules/Web/pages/KorekOffersPage")
);
const KorekServicesPage = lazy(() =>
  import("./modules/Web/pages/KorekServicesPage")
);
const EntertainmentPage = lazy(() =>
  import("./modules/Web/pages/EntertainmentPage")
);
const AboutUsPage = lazy(() =>
  import("./modules/Web/pages/AboutUsPage")
);
const OurLocationPage = lazy(() =>
  import("./modules/Web/pages/OurLocationPage")
);
const ContactPage = lazy(() =>
  import("./modules/Web/pages/ContactPage")
);

export default function BusinessPage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/business" to="/business/home" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        {/* <ContentRoute path="/builder" component={BuilderPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />*/}
        <Route path="/business/home" component={HomePage} /> 
        {/* <ContentRoute path="/blue-program" component={BlueProgramPage} /> */}
        {/* <Route path="/business/blue-program" component={BlueProgramPage} />
        <Route path="/business/korek-offers" component={KorekOffersPage} />
        <Route path="/business/korek-services" component={KorekServicesPage} />
        <Route path="/business/entertainment" component={EntertainmentPage} />
        <Route path="/business/about-us" component={AboutUsPage} />
        <Route path="/business/our-location" component={OurLocationPage} />
        <Route path="/business/contact" component={ContactPage} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
