import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
// import { BlueProgramPage } from "./modules/Web/pages/BlueProgramPage";
// import { HomePage } from "./modules/Web/pages/HomePage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// );
const HomePage = lazy(() =>
  import("./modules/Web/pages/HomePage")
);
const BusinessPage = lazy(() =>
  import("./modules/Business/pages/HomePage")
);
const SOHOPage = lazy(() =>
  import("./modules/Business/pages/SOHOPage")
);
const SMEPage = lazy(() =>
  import("./modules/Business/pages/SMEPage")
);
const OffersPage = lazy(() =>
  import("./modules/Business/pages/OffersPage")
);
const EnterprisePage = lazy(() =>
  import("./modules/Business/pages/EnterprisePage")
);
const GovernmentPage = lazy(() =>
  import("./modules/Business/pages/GovernmentPage")
);
const VIPPage = lazy(() =>
  import("./modules/Business/pages/VIPPage")
);
const BlueProgramPage = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage1")
);
const BlueProgramPage1 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage1")
);
const BlueProgramPage2 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage2")
);
const BlueProgramPage3 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage3")
);
const BlueProgramPage4 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage4")
);
const KorekOffersPage = lazy(() =>
  import("./modules/Web/pages/KorekOffersPage")
);
const KorekServicesPage = lazy(() =>
  import("./modules/Web/pages/KorekServicesPage")
);
const EntertainmentPage = lazy(() =>
  import("./modules/Web/pages/EntertainmentPage")
);
const NewsPage = lazy(() =>
  import("./modules/Web/pages/NewsPage")
);
const AboutUsPage = lazy(() =>
  import("./modules/Web/pages/AboutUsPage")
);
const OurLocationPage = lazy(() =>
  import("./modules/Web/pages/OurLocationPage")
);
const ContactPage = lazy(() =>
  import("./modules/Web/pages/ContactPage")
);

export default function WebPage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/home" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        {/* <ContentRoute path="/builder" component={BuilderPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />*/}
        <Route path="/home" component={HomePage} /> 
        <Route path="/business/home" component={BusinessPage} />
        <Route path="/business/soho" component={SOHOPage} />
        <Route path="/business/sme" component={SMEPage} />
        <Route path="/business/offers" component={OffersPage} />
        <Route path="/business/enterprise" component={EnterprisePage} />
        <Route path="/business/government" component={GovernmentPage} />
        <Route path="/business/vip" component={VIPPage} />
        <Route path="/business/about-us" component={AboutUsPage} />
        <Route path="/business/our-location" component={OurLocationPage} />
        <Route path="/business/contact" component={ContactPage} />
        {/* <ContentRoute path="/blue-program" component={BlueProgramPage} /> */}
        <Route path="/demo-slide1" component={BlueProgramPage1} />
        <Route path="/demo-slide2" component={BlueProgramPage2} />
        <Route path="/demo-slide3" component={BlueProgramPage3} />
        <Route path="/demo-slide4" component={BlueProgramPage4} />
        <Route path="/blue-program" component={BlueProgramPage} />
        <Route path="/korek-offers" component={KorekOffersPage} />
        <Route path="/korek-services" component={KorekServicesPage} />
        <Route path="/entertainment" component={EntertainmentPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/about-us" component={AboutUsPage} />
        <Route path="/our-location" component={OurLocationPage} />
        <Route path="/contact" component={ContactPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
