import { enqueueSnackbar } from "notistack";
import * as requestFromServer from "./topupMyAccountsCrud";
import {topupMyAccountsSlice, callTypes} from "./topupMyAccountsSlice";

const {actions} = topupMyAccountsSlice;

export const fetchMyTopupMyAccounts = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyTopupMyAccounts(queryParams)
    .then(response => {
      const { accountItems,bestOffersForYou } = response.data;
      dispatch(actions.myTopupMyAccountsFetched({ accountItems,bestOffersForYou }));
    })
    .catch(error => {
      error.clientMessage = "Can't find topupMyAccounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const activateOffer = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .activateOffer(queryParams)
    .then(response => {
      const { statusCode } = response.data;
      
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
      dispatch(actions.offerActivated({}));
    })
    .catch(error => {
      error.clientMessage = "Can't find topupMyAccounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateTopupMyAccountsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForTopupMyAccounts(ids, status)
    .then(() => {
      dispatch(actions.topupMyAccountsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update topupMyAccounts status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchLoadDataValididty = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .loadDataValidity(queryParams)
    .then(response => {
      const { addValidityOffers } = response.data;
      dispatch(actions.loadedDataValidity({ addValidityOffers }));
    })
    .catch(error => {
      error.clientMessage = "Can't find topupMyAccounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const AddValididty = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .addValidity(queryParams)
    .then(response => {
      const { addValidityOffers,statusCode } = response.data;
      
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
      dispatch(actions.validityAdded({ addValidityOffers }));
    })
    .catch(error => {
      error.clientMessage = "Can't find topupMyAccounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
