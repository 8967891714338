import axios from "axios";
import { sendRequestDotNet } from "../../../../../_utils/Axios";

export const CUSTOMERS_URL = "api/activeOffers";
export const OFFER_URL = "Offer";

export function findMyActiveOffers() {
  return sendRequestDotNet(`${OFFER_URL}/MyActiveOffers`, {});
}
export function stopAutoRenewal(queryParams) {
  return sendRequestDotNet(`${OFFER_URL}/StopAutoRenewal`, queryParams);
}

export function activeOffer(queryParams) {
  return sendRequestDotNet(`${OFFER_URL}/VasOffers/Activate`, queryParams);
}