import React, { useMemo, useState } from "react";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { useSubheader } from "../../_core/MetronicSubheader";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { enqueueSnackbar } from "notistack";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const history = useHistory();
  const subheader = useSubheader();

  const { user } = useSelector((state) => state.auth);

  const navbarItems = useSelector(state => state.profileSlice.navbarItems, shallowEqual);

  const layoutProps = useMemo(() => ({
    config: uiService.config,
    subheaderCssClasses: uiService.getClasses("subheader", true),
    subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
  }), [uiService]);

  const [show, setShow] = useState("");
  const handleDropdownToggle = (id) => setShow(prev => prev === id ? "" : id);

  const routeChange = (path) => {
    history.push(path);
  };

  const NavDropdownItem = ({ section }) => (
    <div style={{ border: "none", backgroundColor: 'transparent' }} className="card mb-0">
      <NavLink
        to={section.redirectionLink}
        className="dropdown-item"
        activeClassName="menu-item-active"
        onClick={() => {
          setShow("")
          
            // section.redirectionLink == "/korek-services/4" && !user &&
            // enqueueSnackbar("Login Needed", {
            //   variant: "warning",
            // });
          }
        }
      >
        {section.image && <Image className="icon-navigation" width={24} src={section.image} />}
        {section.title}
      </NavLink>
      {section.subsections && section.subsections.map((subsection, index) => (
        <NavLink
          key={index}
          to={subsection.redirectionLink}
          className="dropdown-item"
          activeClassName="menu-item-active"
          onClick={() => setShow("")}
        >
          {subsection.title}
        </NavLink>
      ))}
    </div>
  );

  return (
    <div id="kt_subheader" className={`subheader py-2 py-lg-4 centerFixed logo-subheader-mobile ${layoutProps.subheaderCssClasses}`}>
      <div className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap maxWidthCont`}>
        <Navbar className="navbar-dark" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {navbarItems && navbarItems.filter(item => item.id !== "5").map(item => (
                item.sections.length > 0 ? (
                  <NavDropdown
                    key={item.id}
                    title={
                      <div className="d-flex align-items-center">
                        <NavLink
                          to={item.redirectionLink}
                          className="nav-link dropdown-header-animation"
                          onClick={() => {
                            setShow("");
                            routeChange(item.redirectionLink);
                          }}
                        >
                          {item.title}
                        </NavLink>
                        <ExpandMoreOutlined />
                      </div>
                    }
                    className="pr-5 navbar-not dropdown-header-animation maxWidthCont nav-main"
                    id={`offcanvasNavbarDropdown-expand-xl`}
                    onMouseEnter={() => handleDropdownToggle(item.id)}
                    onMouseLeave={() => handleDropdownToggle(item.id)}
                    show={show === item.id}
                  >

                    <div className="card-columns padding-sub py-0 dropdown-header-animation maxWidthCont" style={{ columnCount: 4 }}>
                      {item.sections.map((section, i) => (
                        <NavDropdownItem key={i} section={section} />
                      ))}
                    </div>
                  </NavDropdown>
                ) : (
                  <NavLink
                    key={item.id}
                    to={item.redirectionLink}
                    style={{ alignContent: "center" }}
                    className="px-5 dropdown-header-animation nav-link"
                    activeClassName="menu-item-active"
                    onClick={() => routeChange(item.redirectionLink)}
                  >
                    {item.title}
                  </NavLink>
                )
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
