import {createSlice} from "@reduxjs/toolkit";

const initialActiveOffersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  listActiveOffers: null,
  listOffersHistory: null,
  activeOfferForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const activeOffersSlice = createSlice({
  name: "activeOffers",
  initialState: initialActiveOffersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getActiveOfferById
    autoRenewalStopped: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // findActiveOffers
    activeOffersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    offerActivated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    myActiveOffersFetched: (state, action) => {
      const { listActiveOffers, listOffersHistory } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.listOffersHistory = listOffersHistory;
      state.listActiveOffers = listActiveOffers;
    },
  }
});
