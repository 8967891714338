import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RechargeLineStatusCssClasses } from "../RechargeLinesUIHelpers";
import * as actions from "../../../_redux/rechargeLines/rechargeLinesActions";
import { useRechargeLinesUIContext } from "../RechargeLinesUIContext";

const selectedRechargeLines = (entities, ids) => {
  const _rechargeLines = [];
  ids.forEach((id) => {
    const rechargeLine = entities.find((el) => el.id === id);
    if (rechargeLine) {
      _rechargeLines.push(rechargeLine);
    }
  });
  return _rechargeLines;
};

export function RechargeLinesUpdateStatusDialog({ show, onHide }) {
  // RechargeLines UI Context
  const rechargeLinesUIContext = useRechargeLinesUIContext();
  const rechargeLinesUIProps = useMemo(() => {
    return {
      ids: rechargeLinesUIContext.ids,
      setIds: rechargeLinesUIContext.setIds,
      queryParams: rechargeLinesUIContext.queryParams,
    };
  }, [rechargeLinesUIContext]);

  // RechargeLines Redux state
  const { rechargeLines, isLoading } = useSelector(
    (state) => ({
      rechargeLines: selectedRechargeLines(state.rechargeLines.entities, rechargeLinesUIProps.ids),
      isLoading: state.rechargeLines.actionsLoading,
    }),
    shallowEqual
  );

  // if there weren't selected rechargeLines we should close modal
  useEffect(() => {
    if (rechargeLinesUIProps.ids || rechargeLinesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeLinesUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for updateing rechargeLine by ids
    dispatch(actions.updateRechargeLinesStatus(rechargeLinesUIProps.ids, status)).then(
      () => {
        // refresh list after deletion
        // dispatch(actions.fetchRechargeLines(rechargeLinesUIProps.queryParams)).then(
        //   () => {
        //     // clear selections list
        //     rechargeLinesUIProps.setIds([]);
        //     // closing delete modal
        //     onHide();
        //   }
        // );
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Status has been updated for selected rechargeLines
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {rechargeLines.map((rechargeLine) => (
              <div className="list-timeline-item mb-3" key={rechargeLine.id}>
                <span className="list-timeline-text">
                  <span
                    className={`label label-lg label-light-${
                      RechargeLineStatusCssClasses[rechargeLine.status]
                    } label-inline`}
                    style={{ width: "60px" }}
                  >
                    ID: {rechargeLine.id}
                  </span>{" "}
                  <span className="ml-5">
                    {rechargeLine.manufacture}, {rechargeLine.model}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <select
            className={`form-control ${RechargeLineStatusCssClasses[status]}`}
            value={status}
            onChange={(e) => setStatus(+e.target.value)}
          >
            <option value="0">Selling</option>
            <option value="1">Sold</option>
          </select>
        </div>
        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            Update Status
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
