import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { checkIsActive, toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import Login from "../extras/Login";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { FormattedMessage } from "react-intl";
import { Topbar } from "../header/Topbar";
import { TopbarMobile } from "../header/TopbarMobile";
import { Button, Col, Container, Nav, NavDropdown, Navbar, Offcanvas, Row, ToggleButton } from "react-bootstrap";
import { SubHeader } from "../subheader/SubHeader";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { QuickUser } from "../extras/offcanvas/QuickUser";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";

export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  let history = useHistory();
  const [showTopbar, setShowTopbar] = useState(false);
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      ulClasses: uiService.getClasses("header_menu_nav", true),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
    };
  }, [uiService]);
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);
  useEffect(() => { }, [subheader]);
  const [show, setShow] = useState("");
  const showDropdown = (e) => {
    setShow(e);
  };
  const hideDropdown = (e) => {
    setShow("");
  };
  const navbarItems = useSelector(
    (state) => state.profileSlice.navbarItems,
    shallowEqual
  );
  const routeChange = (path) => {
    // let path = `/korek-offers/all-offers`;
    history.push(path);
  };

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        {/*end::Logo*/}
        {/*begin::Toolbar*/}
        {/* <div className="d-flex align-items-center"> */}
        <Link className="pl-4 pr-0" to="/">
          <img width="80" alt="logo" src={layoutProps.headerLogo} />
        </Link>
        {layoutProps.asideDisplay && (
          <>
            {/*begin::Aside Mobile Toggle*/}
            {/* <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span/>
                  </button> */}
            {/*end::Aside Mobile Toggle*/}
          </>
        )}

        {layoutProps.headerMenuSelfDisplay && (
          <>
            {/*begin::Header Menu Mobile Toggle*/}
            {/* <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                    <span/>
                  </button> */}
            {/*end::Header Menu Mobile Toggle*/}
          </>
        )}

        {/*begin::Topbar Mobile Toggle*/}
        {/* <button
                className="btn btn-hover-text-primary p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
            </button> */}
        {/*end::Topbar Mobile Toggle*/}
        <div
          style={{
            display: "inline-flex",
            placeItems: "center",
          }}
        >
          {window.location.pathname.split("/").slice(1)[0] == "business" ? (
            <NavLink className="menu-link ml-4 mr-0" to="/">
              <span className="menu-text text-light" style={{fontSize:"10px"}}>
                <FormattedMessage id="FOOTER.PERSONAL"></FormattedMessage>
              </span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          ) : (
            <NavLink className="menu-link ml-4 mr-0" to="/business/home">
              <span className="menu-text text-light" style={{fontSize:"10px"}}>
                <FormattedMessage id="FOOTER.Business"></FormattedMessage>
              </span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          )}

          {layoutProps.viewSearchDisplay && <SearchDropdown />}

          {layoutProps.viewUserDisplay && <Login />}

          {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}
        {
          layoutProps.asideDisplay && window.location.pathname.split('/').slice(1)[0] == "my-korek" && user &&
                    <button className="btn p-0 burger-icon burger-icon-left " id="kt_aside_mobile_toggle">
                            <span/>
                          </button>
        }

          {/* <div onClick={() => setShowTopbar(!showTopbar)}>
            <span class="svg-icon svg-icon-light svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <title>Stockholm-icons / General / Other1</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <circle fill="#000000" cx="12" cy="5" r="2" />
                  <circle fill="#000000" cx="12" cy="12" r="2" />
                  <circle fill="#000000" cx="12" cy="19" r="2" />
                </g>
              </svg>
            </span>
          </div> */}
          <UserNotificationsDropdown/>
          {/* {showTopbar && <TopbarMobile />} */}
        </div>
      </div>
      {/*end::Toolbar*/}
      {/* </div> */}
      {/*end::Header Mobile*/}
    </>
  );
}
