/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { useState } from "react";
import { useIntl } from "react-intl";

export function StatsWidget12({ className, symbolShape, baseColor,data }) {
  const uiService = useHtmlClassService();
  const intl = useIntl()
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);
  const [dataY,setY] = useState()
  useEffect(() => {
    if(data)
    {
      let dtY = []
      let dtX = []
      data.data.map(x=>{
        dtY.push(x.value)
        dtX.push(x.date)
      })
      setY(dtY)
      
    const element = document.getElementById("kt_stats_widget_12_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps,dtX,dtY);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
    }
  }, [layoutProps]);

  useEffect(() => {
  }, []);

  function getChartOption(layoutProps,dtX,dtY) {
    const options = {
      series: [
        {
          name: intl.formatMessage({id:"DASHBOARD.VOICE_USAGE"}),
          data: dtY,
        },
      ],   
          chart: {
            type: "area",
            height: 250,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
            // sparkline: {
            //   enabled: true,
            // },
          },
          plotOptions: {},
          legend: {
            show: true,
          },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: .5,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [layoutProps.colorsThemeBaseSuccess],
        },
      xaxis: {
            categories: dtX,
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },               
            position:"bottom",
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            labels: {
              show: true,
              style: {
                colors: layoutProps.colorsGrayGray500,
                fontSize: "12px",
                fontFamily: layoutProps.fontFamily,
              },
            },
            crosshairs: {
              show: true,
              position: "front",
              stroke: {
                color: layoutProps.colorsGrayGray300,
                width: 1,
                dashArray: 3,
              },
            },
            tooltip: {
              enabled: true,
              formatter: undefined,
              offsetY: 0,
              style: {
                fontSize: "12px",
                fontFamily: layoutProps.fontFamily,
              },
            },
      },
      yaxis: {  
        categories: dtY,
            // position:"left",
            // min: 0,
            // max: 100,
            labels: {
              show: true,
              style: {
                colors: layoutProps.colorsGrayGray500,
                fontSize: "12px",
                fontFamily: layoutProps.fontFamily,
              },
            },
      },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
          y: {
            formatter: function(val) {
              return val + " GB";
            },
          },
        },
        colors: [layoutProps.colorsThemeLightSuccess],
        markers: {
          colors: [layoutProps.colorsThemeLightSuccess],
          strokeColor: [layoutProps.colorsThemeBaseSuccess],
          strokeWidth: 3,
        },
    };
    return options;
  }
  
  return (
    
    <div className={`card card-custom ${className}`}>
      <div className="card-body p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
      {
        data &&
          <span
            className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}
          >
          <div className="d-flex flex-column text-left">
            <span className="text-dark-75 font-weight-bolder font-size-h3">
            {data.subtitle}
            </span>
            {/* <span className="text-dark-50 font-size-h6">
            {data.subtitle}
            </span> */}
          </div>
          </span>
      }
        </div>
        <div
          id="kt_stats_widget_12_chart"
          className="card-rounded-bottom"
          style={{ height: "250px" }}
        ></div>
      </div>
    </div>
  );
}
