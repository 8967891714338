import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import * as auth from "../Auth";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import "../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { blockNumber } from "./_redux/Profile/profileActions";
import {
  autoCreditLoan,
  fetchLoansDetails,
} from "../MyKorek/_redux/rechargeLines/rechargeLinesActions";
import { Chip } from "@material-ui/core";
import { applyLoan } from "../MyKorek/_redux/rechargeLines/rechargeLinesActions";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: 'lef',
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

function Controls(props) {
  // Fields
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.rechargeLines }),
    shallowEqual
  );
  const { autoLoanEnabled, loans } = currentState;

  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedCreditLoan, setSelectedCreditLoan] = useState();
  // useEffect(() => {
  //   sendRequestDotNet("Profile/LoadData",{},{}).then((response)=>{
  //     setLocations(response.data.locations)
  //     setGenders(response.data.genders)
  //     setSimLanguages(response.data.simLanguages)
  // })},[]);

  function handleClick() {
    dispatch(
      applyLoan({
        idLoan: selectedCreditLoan.idLoan,
        loanType: selectedCreditLoan.loanType,
      })
    );
    setSelectedCreditLoan();
    // alert('You clicked the Chip.');
  }
  useEffect(() => {
    dispatch(
      fetchLoansDetails({
        loanType: 0,
      })
    );
  }, []);

  return (
    <div className="my-active-offers">
      <div className="card card-custom card-stretch">
        <div className="card-header py-3">
          <div className="card-title">
            <h1 className="card-label font-weight-bolder text-dark">
              <FormattedMessage id="AUTH.CALL_BLOCKING"></FormattedMessage>
            </h1>
            {/* <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal informaiton
          </span> */}
          </div>
        </div>
        <div className="card-body">
          <div className="recharge-tab">
            <Row>
              <Col xl={10}>
                <input
                  onChange={(e) => setMobileNumber(e.target.value)}
                  value={mobileNumber}
                  className="input-home"
                  placeholder={intl.formatMessage({
                    id: "AUTH.CALL_BLOCKING_INPUT",
                  })}
                />
              </Col>
              <Col className="text-center mx-auto input-btn py-5" xl={2}>
                <Button
                  disabled={!mobileNumber}
                  onClick={() => {
                    dispatch(
                      blockNumber({
                        msisdn: mobileNumber,
                      })
                    );
                    setMobileNumber("");
                  }}
                  className="btn btn-danger"
                >
                  <b>
                    <FormattedMessage id="AUTH.BLOCK"></FormattedMessage>
                  </b>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="card card-custom card-stretch mt-5">
        {/* <Row style={{ padding: "16px 16px 0px 16px" }}>
          <Col xl={6}>
            <div className="head-title">Credit Loan</div>
          </Col>
        </Row> */}
        <div className="card-header py-3">
          <div className="card-title">
            <h1 className="card-label font-weight-bolder text-dark">
              <FormattedMessage id="AUTH.CREDIT_LOAN"></FormattedMessage>
            </h1>
          </div>
        </div>
        <div className="card-body py-0">
          <Row>
            <Col xl={12}>
              <div className={classes.root}>
                {loans && loans.filter((x) => x.loanType == 1).length > 0 ? (
                  loans
                    .filter((x) => x.loanType == 1)
                    .map((x, i) => (
                      <Chip
                        key={i}
                        label={x.subDescription}
                        color="secondary"
                        onClick={() => setSelectedCreditLoan(x)}
                        className={classes.chip}
                        variant={
                          selectedCreditLoan &&
                          selectedCreditLoan.idLoan == x.idLoan
                            ? "default"
                            : "outlined"
                        }
                      />
                    ))
                ) : loans ? (
                  <div className="mb-4">
                    <FormattedMessage id="AUTH.NO_LOANS_AVAILABLE"></FormattedMessage>
                  </div>
                ) : (
                  <span className="m-5 spinner spinner-dark"></span>
                )}
              </div>
            </Col>
            {loans && loans.filter((x) => x.loanType == 1).length > 0 && (
              <Col className="text-left input-btn mx-3 pb-5" xl={2}>
                <Button
                  disabled={!selectedCreditLoan}
                  onClick={handleClick}
                  className="btn btn-warning"
                >
                  <b><FormattedMessage id="AUTH.APPLY"></FormattedMessage></b>
                </Button>
              </Col>
            )}
            <Col className="text-left input-btn pb-5" xl={12}>
              <Col className="auto-renewal">
                <div className="checkbox-inline">
                  <label className="checkbox m-0">
                    <input
                      type="checkbox"
                      checked={autoLoanEnabled && autoLoanEnabled}
                      onChange={() => {
                        dispatch(
                          autoCreditLoan({
                            action: autoLoanEnabled && autoLoanEnabled ? 0 : 1,
                          })
                        );
                        // dispatch(fetchMyActiveOffers());
                      }}
                    />
                    <span></span>
                    <FormattedMessage id="AUTH.AUTO_CREDIT_LOAN"></FormattedMessage>
                  </label>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(Controls);
