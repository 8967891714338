import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Redirect, Switch, Route, Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import MyKorekPage from "./modules/MyKorek/pages/MyKorekPage";
import MyNumberMyChoice from "./modules/Web/pages/KorekServicesPages/MyNumberMyChoice";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const HomePage = lazy(() =>
  import("./modules/Web/pages/HomePage")
);
const BlueProgramPage = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage1")
);
const BlueProgramPage1 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage1")
);
const BlueProgramPage2 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage2")
);
const BlueProgramPage3 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage3")
);
const BlueProgramPage4 = lazy(() =>
  import("./modules/Web/pages/BlueProgramPage4")
);
const KorekOffersPage = lazy(() =>
  import("./modules/Web/pages/KorekOffersPage")
);
const KorekServicesPage = lazy(() =>
  import("./modules/Web/pages/KorekServicesPage")
);
const EntertainmentPage = lazy(() =>
  import("./modules/Web/pages/EntertainmentPage")
);
const NewsPage = lazy(() =>
  import("./modules/Web/pages/NewsPage")
);

const AboutUsPage = lazy(() =>
  import("./modules/Web/pages/AboutUsPage")
);
const ContactPage = lazy(() =>
  import("./modules/Web/pages/ContactPage")
);
const OurLocationPage = lazy(() =>
  import("./modules/Web/pages/OurLocationPage")
);


const BusinessPage = lazy(() =>
  import("./modules/Business/pages/HomePage")
);
const SOHOPage = lazy(() =>
  import("./modules/Business/pages/SOHOPage")
);
const SMEPage = lazy(() =>
  import("./modules/Business/pages/SMEPage")
);
const OffersPage = lazy(() =>
  import("./modules/Business/pages/OffersPage")
);
const EnterprisePage = lazy(() =>
  import("./modules/Business/pages/EnterprisePage")
);
const GovernmentPage = lazy(() =>
  import("./modules/Business/pages/GovernmentPage")
);
const VIPPage = lazy(() =>
  import("./modules/Business/pages/VIPPage")
);
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* {isAuthorized && <Redirect to="/my-korek"/>} */}
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/my-korek" to="/my-korek/my-account" />
        }
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/home" />
        }
        <ContentRoute path="/my-korek/dashboard" component={DashboardPage} />
        <Route path="/home" component={HomePage} /> 
        <Route path="/blue-program" component={BlueProgramPage} />
        <Route path="/demo-slide1" component={BlueProgramPage1} />
        <Route path="/demo-slide2" component={BlueProgramPage2} />
        <Route path="/demo-slide3" component={BlueProgramPage3} />
        <Route path="/demo-slide4" component={BlueProgramPage4} />
        <Route path="/korek-offers" component={KorekOffersPage} />
        
        <Route path="/business/home" component={BusinessPage} />
        <Route path="/business/soho" component={SOHOPage} />
        <Route path="/business/sme" component={SMEPage} />
        <Route path="/business/offers" component={OffersPage} />
        <Route path="/business/enterprise" component={EnterprisePage} />
        <Route path="/business/government" component={GovernmentPage} />
        <Route path="/business/vip" component={VIPPage} />
        <Route path="/business/about-us" component={AboutUsPage} />
        <Route path="/business/our-location" component={OurLocationPage} />
        <Route path="/business/contact" component={ContactPage} />
        {/* <ContentRoute path="/my-korek/builder" component={BuilderPage} /> */}
        {/* <ContentRoute path="/my-korek/my-page" component={MyPage} /> */}
        {/* <Route path="/google-material" component={GoogleMaterialPage} /> */}
        {/* <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        {/* {heroes.map(hero => (<Link to={'heroes/' + hero.id} />)} */}
        {/* <Link to={'/korek-offers/' + "1"} />
        <Link to={'/korek-offers/' + "2"} />
        <Link to={'/korek-offers/' + "3"} />
        <Route path="/korek-offers/:id" component={KorekOffersPage} /> */}
        <Route path="/korek-services" component={KorekServicesPage} />
        <Route path="/about-us" component={AboutUsPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/our-location" component={OurLocationPage} />
        <Route path="/entertainment" component={EntertainmentPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/korek-services/4" component={MyNumberMyChoice} />
        <Route path="/my-korek/e-commerce" component={ECommercePage} />
        <Route path="/my-korek/my-account" component={UserProfilepage} />
        <Route path="/my-korek" component={MyKorekPage} />
        {/* <Route path="/my-korek/user-profile" component={UserProfilepage} /> */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
