import { enqueueSnackbar } from "notistack";
import * as requestFromServer from "./rechargeLinesCrud";
import {rechargeLinesSlice, callTypes} from "./rechargeLinesSlice";

const {actions} = rechargeLinesSlice;

export const fetchMyRechargeLines = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyRechargeLines(queryParams)
    .then(response => {
      const { rechargeLinesList } = response.data;
      dispatch(actions.myRechargeLinesFetched({ rechargeLinesList }));
    })
    .catch(error => {
      error.clientMessage = "Can't find rechargeLines";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchHistoryPrepaidRecharge = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findHistoryPrepaidRecharge(queryParams)
    .then(response => {
      const { list } = response.data;
      dispatch(actions.historyPrepaidRechargeFetched({ list }));
    })
    .catch(error => {
      error.clientMessage = "Can't find rechargeLines";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateRechargeLinesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForRechargeLines(ids, status)
    .then(() => {
      dispatch(actions.rechargeLinesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update rechargeLines status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const rechargeScratchCard = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .rechargeScratchCardd(params)
    .then(response => {
      const { statusCode } = response.data;
      dispatch(actions.scratchCardRecharged({  }));
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
    })
    .catch(error => {
      error.clientMessage = "Can't update rechargeLines status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const rechargePrePaid = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .rechargePrePaid(params)
    .then(response => {
      const { statusCode,url } = response.data;
      dispatch(actions.prePaidRecharged({  }));
      
      url && window.open(url, '_blank')
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
    })
    .catch(error => {
      error.clientMessage = "Can't update rechargeLines status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const fetchLoansDetails = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLoansDetails(queryParams)
    .then(response => {
      const { loans ,autoLoanEnabled} = response.data;
      dispatch(actions.loansDetailsFetched({ loans ,autoLoanEnabled }));
    })
    .catch(error => {
      error.clientMessage = "Can't find rechargeLines";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const applyLoan = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .applyLoan(params)
    .then(response => {
      const { statusCode } = response.data;
      dispatch(actions.loanApplied({ statusCode }));
      
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
    })
    .catch(error => {
      error.clientMessage = "Can't update rechargeLines status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const autoCreditLoan = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .autoCreditLoan(params)
    .then(response => {
      const { statusCode } = response.data;
      dispatch(actions.autoCreditLoanDone({ statusCode }));
      
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
    })
    .catch(error => {
      error.clientMessage = "Can't update rechargeLines status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};