import React from "react";
import { Route } from "react-router-dom";
import { MyBillsLoadingDialog } from "./myBills-loading-dialog/MyBillsLoadingDialog";
import { MyBillsUpdateStatusDialog } from "./myBills-update-status-dialog/MyBillsUpdateStatusDialog";
import { MyBillsCard } from "./MyBillsCard";
import { MyBillsUIProvider } from "./MyBillsUIContext";
import "../../../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { PayBillDialog } from "./pay-bill-dialog/PayBillDialog";
import { OpenDetailsDialog } from "./open-details-dialog/OpenDetailsDialog";

export function MyBillsPage({ history }) {
  const myBillsUIEvents = {
    openUpdateMyBillsStatusDialog: () => {
      history.push("/my-korek/my-bills/updateStatus");
    },
    openPayBill: (id) => {
      history.push(`/my-korek/my-bills/pay-bill/${id}`);
    },
    openDetails: (id) => {
      history.push(`/my-korek/my-bills/view-details/${id}`);
    },
  };

  return (
    <MyBillsUIProvider myBillsUIEvents={myBillsUIEvents}>
      <MyBillsLoadingDialog />
      <Route path="/my-korek/my-bills/updateStatus">
        {({ history, match }) => (
          <MyBillsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/my-korek/my-bills");
            }}
          />
        )}
      </Route>
      <Route path="/my-korek/my-bills/pay-bill/:id">
        {({ history, match }) => (
          <PayBillDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/my-korek/my-bills");
            }}
          />
        )}
      </Route>
      <Route path="/my-korek/my-bills/view-details/:id">
        {({ history, match }) => (
          <OpenDetailsDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/my-korek/my-bills");
            }}
          />
        )}
      </Route>
      <MyBillsCard />
    </MyBillsUIProvider>
  );
}
