/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { Row,Col, Image } from "react-bootstrap";
import { KTCookie } from "../../../../_assets/js/components/cookie";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const intl = useIntl();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const userProfile = useSelector(
    (state) => state.profileSlice.userProfile,
    shallowEqual
  );
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  // console.log(user)
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {
          user && user.userProfile.isBlue && 
        <li
          className={`menu-item blue-program-btn mb-5 ${getMenuItemActive("/my-korek/blue-program", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/blue-program">
            <Row><Col style={{alignSelf:"center",paddingRight:"5px"}} xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Image alt="logo" height={32} src={toAbsoluteUrl(
                  "/media/korek/blue-prog-logo.svg"
                )}/>
              </Col>
            <Col className="pl-5" xl={9} lg={9} md={9} sm={9} xs={9}>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.BLUE_PROG" })}</span>
            <span className="menu-desc">{user.userProfile.loyaltyPoints}</span>
            </Col></Row>
          </NavLink>
        </li>
        }
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.Dashboard" })}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/my-account", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/my-account">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.MyAccount" })}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/topup-my-account", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/topup-my-account">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Code/Plus.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.TOPUP" })}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/recharge-line", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/recharge-line">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Media/Repeat.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.Recharge" })}</span>
          </NavLink>
        </li>
          {
            KTCookie.getCookie("accountType") && KTCookie.getCookie("accountType") == 2 &&
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/my-bills", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/my-bills">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/General/Clipboard.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.Bills" })}</span>
          </NavLink>
        </li>
}
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/active-offers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/active-offers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Sale2.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.ActiveOffer" })}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/active-vas-offers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/active-vas-offers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Sort3.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.VASOffer" })}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/support", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/support">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Devices/Headphones.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "Aside.Support" })}</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
