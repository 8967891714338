import React from "react";
import { Image, Row, Col, Button, Card } from "react-bootstrap";
import "../../../../../_metronic/_assets/sass/pages/my-korek/support.scss";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "../../../../../_metronic/_helpers/GrayBox";
import { FormattedMessage } from "react-intl";

export function SupportPage() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="support">
      <div className="more-details">
        <div className="card card-custom card-stretch mb-5">
          <div className="card-body">
            <Row className="head-title">
              <FormattedMessage id="SUPPORT.SUPPORT"></FormattedMessage>
              {/* <FormattedMessage id="SUPPORT.SUPPORT"></FormattedMessage> */}
            </Row>
            <h5
              style={{ fontWeight: "400" }}
              className="title-box p-style pb-3"
            >
              <FormattedMessage id="SUPPORT.P1"></FormattedMessage>
            </h5>
            <div className="gray-box my-3">
              <ExpansionPanel
                square
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <h3 className="title-box">
                    <FormattedMessage id="SUPPORT.Q"></FormattedMessage>
                  </h3>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <h5 className="title-box">
                    <FormattedMessage id="SUPPORT.ANS"></FormattedMessage>
                  </h5>
                  <ul className="p-style">
                    <li>
                      {" "}
                      <FormattedMessage id="SUPPORT.LI1"></FormattedMessage>
                    </li>
                    <li>
                      {" "}
                      <FormattedMessage id="SUPPORT.LI2"></FormattedMessage>
                    </li>
                  </ul>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
            <p style={{ fontWeight: "400" }} className="db">
              <FormattedMessage id="SUPPORT.MORE_INFO"></FormattedMessage>
            </p>
          </div>
        </div>
        <div className="card card-custom card-stretch mb-5">
          <div className="card-body">
            <Row className="head-title">
              <FormattedMessage id="SUPPORT.MORE_DETAILS"></FormattedMessage>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Img
                    className="mx-auto my-5"
                    variant="top"
                    src="/media/korek/support1.svg"
                  />
                  <Card.Body className="p-5">
                    <Card.Title className="head-title text-center mb-0">
                      <FormattedMessage id="SUPPORT.CUST_SERVICE"></FormattedMessage>
                    </Card.Title>
                    <Card.Text className="text-center">
                      <span className="db">
                      </span>{" "}
                      <FormattedMessage id="SUPPORT.PHONE"></FormattedMessage>
                      <span className="db"> 411 </span>
                        {" "}
                        <FormattedMessage id="SUPPORT.FREE"></FormattedMessage>
                      <FormattedMessage id="SUPPORT.FROM_KOREK_NBR"></FormattedMessage>
                      <br />
                      <FormattedMessage id="SUPPORT.EMAIL"></FormattedMessage>{" "}
                      <a href="mailto:customer.care@korektel.com"><span className="db">customer.care@korektel.com</span></a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img
                    className="mx-auto my-5"
                    variant="top"
                    src="/media/korek/support2.svg"
                  />
                  <Card.Body className="p-5">
                    <Card.Title className="head-title text-center mb-0">
                      <FormattedMessage id="SUPPORT.CORP_SALES"></FormattedMessage>
                    </Card.Title>
                    <Card.Text className="text-center">
                      <FormattedMessage id="SUPPORT.EMAIL"></FormattedMessage>{" "}
                      <a href="mailto:corporate@korektel.com"><span className="db">corporate@korektel.com</span></a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img
                    className="mx-auto my-5"
                    variant="top"
                    src="/media/korek/support3.svg"
                  />
                  <Card.Body className="p-5">
                    <Card.Title className="head-title text-center mb-0">
                      <FormattedMessage id="SUPPORT.CONTACT"></FormattedMessage>
                    </Card.Title>
                    <Card.Text className="text-center">
                      <FormattedMessage id="SUPPORT.EMAIL"></FormattedMessage>{" "}
                      <a href="mailto:info@korektel.com"><span className="db">info@korektel.com</span></a>
                      <FormattedMessage id="SUPPORT.TEL"></FormattedMessage>{" "}
                      <span className="db" dir="ltr">+964 750 445 0022</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
