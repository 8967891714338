import React, { useEffect, useMemo } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Checkbox,
} from "../../../../../_metronic/_partials/controls";
import { Card as CardC } from "../../../../../_metronic/_partials/controls";
import { ActiveOffersFilter } from "./activeOffers-filter/ActiveOffersFilter";
import { ActiveOffersTable } from "./activeOffers-table/ActiveOffersTable";
import { useActiveOffersUIContext } from "./ActiveOffersUIContext";
import "../../../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { Button, Row, Col, Image, ProgressBar } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchMyActiveOffers, stopAutoRenewal } from "../../_redux/activeOffers/activeOffersActions";
import moment from "moment/moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FormattedMessage, useIntl } from "react-intl";

export function ActiveOffersCard() {
  const activeOffersUIContext = useActiveOffersUIContext();
  const dispatch = useDispatch()
  const activeOffersUIProps = useMemo(() => {
    return {
      ids: activeOffersUIContext.ids,
      queryParams: activeOffersUIContext.queryParams,
      setQueryParams: activeOffersUIContext.setQueryParams,
      openUpdateActiveOffersStatusDialog:
        activeOffersUIContext.openUpdateActiveOffersStatusDialog,
    };
  }, [activeOffersUIContext]);

  
  useEffect(() => {  
    dispatch(fetchMyActiveOffers())
  },[]);
  
  const { currentState } = useSelector(
    (state) => ({ currentState: state.activeOffers }),
    shallowEqual
  );
  const { listActiveOffers } = currentState;
  const intl = useIntl()
  return (
    <div className="my-active-offers">
      <div className="card card-custom card-stretch mb-5">
        <div className="card-body">
          <Row className="head-title pb-4"><FormattedMessage id="ACTIVE_OFFERS.ACTIVE_OFFERS">
            </FormattedMessage></Row>
          {listActiveOffers ? (
            listActiveOffers.map((acOffers, i) => (
              <>
                <Row className="my-5">
                  <Col xl={2}>
                    <div className="symbol symbol-100 symbol-xxl-150 mr-5 align-self-start align-self-xxl-center">
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage: `url("${acOffers.imageUrl}")`,
                        }}
                      ></div>
                    </div>
                  </Col>
                  <Col xl={10}>
                    <Row className="mx-auto" style={{ width: "100%" }}>
                      <Col className="pb-2" xl={12}>
                        <div className="font-weight-bolder offer-name">
                          {acOffers.name}
                        </div>
                      </Col>
                      <Col className="pb-4" xl={12}>
                        <div className="font-weight-bolder offer-price">
                          {acOffers.price}
                        </div>
                      </Col>

                      {acOffers.items.map((item) => (
                        <>
                        <Col className="my-3"  xxl={4} xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Row className="account-info">
                            <Col xl={2}>
                              <Image
                                className=""
                                src={
                                  "/media/korek/account-" + item.idType + ".png"
                                }
                              ></Image>
                            </Col>{" "}
                            <Col xl={10}>
                              <span className="upper-title">
                                {item.itemName}
                              </span>
                              <span className="lower-title">
                                {item.itemValue}
                              </span>
                              <ProgressBar
                                variant="warning"
                                now={item.percentage}
                              />
                            </Col>
                          </Row>
                        </Col>
                        </>
                      ))}
                      <Col className="pt-4" xl={12}>
                        <Row>
                          <Col className="offer-exp">
                          {acOffers.name}{" "}
                            <FormattedMessage id="ACTIVE_OFFERS.YOUTH_OFFER">
                              </FormattedMessage>{" "}
                            {moment(acOffers.expiryDate).format("YYYY-MM-DD")}
                          </Col>
                          {acOffers.isRenewable && (
                            <Col className="auto-renewal">
                              <div className="checkbox-inline">
                                <label className="checkbox m-0">
                                  <input
                                    type="checkbox"
                                    checked={acOffers.isRenewable}
                                    onChange={() => {
                                      dispatch(
                                        stopAutoRenewal({
                                          idData: acOffers.id,
                                          offerName: acOffers.name,
                                        })
                                      );
                                      dispatch(fetchMyActiveOffers());

                                    }}
                                  />
                                  <span></span><FormattedMessage id="ACTIVE_OFFERS.AUTO_RENEWAL">
                              </FormattedMessage>
                                </label>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Button className="btn btn-outline-primary mb-5"><b>Rate Offer</b></Button> */}
                {i == acOffers.length - 1 ? <></> : <Divider />}
              </>
            ))
          ) : (
            <SkeletonTheme height={150}>
              <Row>
                <Col xl={2}>
                  <Skeleton count={1} />
                </Col>
                <Col xl={10}>
                  <Skeleton count={1} />
                </Col>
              </Row>
            </SkeletonTheme>
          )}

{listActiveOffers?.length == 0 && <Row className="px-5"><FormattedMessage id="NO_DATA_FOUND">
            </FormattedMessage></Row>}
          {/* 
            <Row className="my-5">
              <Col xl={2}>
                <div className="symbol symbol-150 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url("/media/korek/active-offer2.png")`,
                    }}
                  ></div>
                </div>
              </Col>
              <Col xl={10}>
                  <Row className="mx-auto pt-5" style={{ width: "100%" }}>
                    <Col className="pb-4" xl={12}>
                <div className="font-weight-bolder offer-name">
                Golden Offer
                </div></Col>
                    <Col>
                      <Row className="account-info">
                        <Col xl={3}>
                          <Image
                            className=""
                            src="/media/korek/account-2.png"
                          ></Image>
                        </Col>{" "}
                        <Col xl={9}>
                          <span className="upper-title">Internet</span>
                          <span className="lower-title">24 Hour</span>
                          <ProgressBar variant="warning" now={60} />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="account-info">
                        <Col xl={3}>
                          <Image
                            className=""
                            src="/media/korek/account-3.png"
                          ></Image>
                        </Col>{" "}
                        <Col xl={9}>
                          <span className="upper-title">Voice</span>
                          <span className="lower-title">209/300 min</span>
                          <ProgressBar variant="warning" now={40} />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="account-info">
                        <Col xl={3}>
                          <Image
                            className=""
                            src="/media/korek/account-4.png"
                          ></Image>
                        </Col>{" "}
                        <Col xl={9}>
                          <span className="upper-title">Validity</span>
                          <span className="lower-title">10/40 days</span>
                          <ProgressBar variant="warning" now={80} />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="pt-4" xl={12}>
                <div className="offer-exp">
                  Youth Offer expired on 07-12-2023
                </div></Col>
                  </Row>
              </Col>
            </Row>
            
            <Image
                            className="pb-2 pr-2"
                            src="/media/korek/star.png"
                          ></Image><span className="rating"><b>4.0</b> (Your Rating)</span> */}
        </div>
      </div>
      <CardC>
        <CardHeader title={intl.formatMessage({id:"ACTIVE_OFFERS.BUNDLES_HISTORY"})}>
          {/* <CardHeaderToolbar>
            <ActiveOffersFilter />
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody className="p-0">
          {/* {activeOffersUIProps.ids.length > 0 && (
          <>
            <ActiveOffersGrouping />
          </>
        )} */}
          <ActiveOffersTable />
        </CardBody>
      </CardC>
    </div>
  );
}
