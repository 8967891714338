/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { FormattedMessage } from "react-intl";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditMyBillPage, openDeleteMyBillDialog,openPayBill,openDetails }
) => (
  
  <>
{  
  row.statusId == "0" && <a
        className="btn btn-warning btn-sm mx-3"
        onClick={() => openPayBill(row.id)}
      >
      <b><FormattedMessage id="BILLS.PAY"></FormattedMessage></b>
      </a>}
{  
  // row.statusId == "1" && 
  <a
        className="btn btn-light btn-sm mx-3"
        onClick={() => openDetails(row.id)}
      >
      <b><FormattedMessage id="BILLS.DETAILS"></FormattedMessage></b>
      </a>}
  {/* <Button className="btn btn-warning" size="sm">
              <b>Pay</b>
            </Button> */}
    {/* <OverlayTrigger
      overlay={<Tooltip id="myBills-edit-tooltip">Edit myBill</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditMyBillPage(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a>
    </OverlayTrigger>

    <> </>
    <OverlayTrigger
      overlay={<Tooltip id="myBills-delete-tooltip">Delete myBill</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteMyBillDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
    </OverlayTrigger> */}
  </>
);
