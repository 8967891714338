import {createSlice} from "@reduxjs/toolkit";

const initialMyBillsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  myBillsList: null,
  url: undefined,
  doc: undefined,
  myBillForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const myBillsSlice = createSlice({
  name: "myBills",
  initialState: initialMyBillsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    myBillsFetched: (state, action) => {
      const { list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = list;
    },
    paidBill: (state, action) => {
      const { url } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.url = url;
    },
    downloadDetails: (state, action) => {
      const { doc } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.doc = doc;
    },
    // myBillsUpdateState
    myBillsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
