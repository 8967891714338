import React, { useLayoutEffect } from "react";
import { KTUtil } from "../../_assets/js/components/util";
import KTLayoutHeader from "../../_assets/js/layout/base/header";
import KTLayoutHeaderMenu from "../../_assets/js/layout/base/header-menu";
import KTLayoutHeaderTopbar from "../../_assets/js/layout/base/header-topbar";
import KTLayoutBrand from "../../_assets/js/layout/base/brand";
import KTLayoutAside from "../../_assets/js/layout/base/aside";
import KTLayoutAsideToggle from "../../_assets/js/layout/base/aside-toggle";
import KTLayoutAsideMenu from "../../_assets/js/layout/base/aside-menu";
import KTLayoutContent from "../../_assets/js/layout/base/content";
import KTLayoutFooter from "../../_assets/js/layout/base/footer";
import KTLayoutSubheader from "../../_assets/js/layout/base/subheader";
import KTLayoutScrolltop from "../../_assets/js/layout/extended/scrolltop";
import KTLayoutStickyCard from "../../_assets/js/layout/base/sticky-card";
import KTLayoutStretchedCard from "../../_assets/js/layout/base/stretched-card";
import KTLayoutQuickPanel from "../../_assets/js/layout/extended/quick-panel";
import KTLayoutQuickUser from "../../_assets/js/layout/extended/quick-user";
import { deviceCheck, sendRequestDotNet } from "../../../_utils/Axios";
import { KTCookie } from "../../_assets/js/components/cookie";
import {
  getMainLoadData,
  initialization,
} from "../../../app/modules/UserProfile/_redux/Profile/profileActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom"; // Import useLocation

export function LayoutInit() {
  const dispatch = useDispatch();
  const location = useLocation()
  const { user } = useSelector((state) => state.auth);

  useLayoutEffect(() => {
    KTUtil.ready(() => {
      // Initialization of layout components
      KTLayoutHeader.init("kt_header", "kt_header_mobile");
      KTLayoutHeaderMenu.init("kt_header_menu", "kt_header_menu_wrapper");
      KTLayoutHeaderTopbar.init("kt_header_mobile_topbar_toggle");
      KTLayoutBrand.init("kt_brand");
      KTLayoutAside.init("kt_aside");
      KTLayoutAsideToggle.init("kt_aside_toggle");
      KTLayoutAsideMenu.init("kt_aside_menu");
      KTLayoutContent.init("kt_content");
      KTLayoutFooter.init("kt_footer");
      KTLayoutSubheader.init("kt_subheader");
      KTLayoutScrolltop.init("kt_scrolltop");
      KTLayoutStickyCard.init("kt_page_sticky_card");
      KTLayoutStretchedCard.init("kt_page_stretched_card");
      KTLayoutQuickPanel.init("kt_quick_panel");
      KTLayoutQuickUser.init("kt_quick_user");

      const didCookie = KTCookie.getCookie("did");

      // Check if the did cookie exists
      if (didCookie && didCookie !== "undefined") {
        // Directly call initialization and getMainLoadData if did exists
        (location.pathname === "/" || location.pathname === "/home") && dispatch(initialization());
        dispatch(getMainLoadData());
      } else {
        // Proceed with the registration declaration if did does not exist
        let data = {
          udid: crypto.randomUUID(),
          devicemodel: deviceCheck(),
        };
        let headers = { idusertype: "3" };

        sendRequestDotNet("Registration/Declaration", data, headers).then((response) => {
          KTCookie.setCookie("did", response.data.did);
          KTCookie.deleteCookie("token");

          sendRequestDotNet("Registration/SkipLogin", {}, headers).then((response) => {
            KTCookie.setCookie("isSkipLogin", true);
            KTCookie.setCookie("idUserType", "1");
            KTCookie.setCookie("logout", false);
            KTCookie.setCookie("idGuest", response.data.idGuest);
            (location.pathname === "/" || location.pathname === "/home") && dispatch(initialization());
            dispatch(getMainLoadData());
          });
        });
      }
    });
  }, [dispatch]);

  return <></>;
}
