import React, { Fragment } from "react";
import {  Box } from "@material-ui/core";

import Nav from "../Nav/Nav";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const FormWrapper = ({
  children,
  isLastStep,
  goToPreviousStep,
  canGoBack,
  actionLabel,
  currentStep,
  wizard
}) => {
  return (
    <Fragment>
      <Nav currentStep={currentStep} wizard={wizard} />
      {
        canGoBack && 
        <Button className="btn btn-outline-primary mb-2" type="button" onClick={goToPreviousStep} disabled={!canGoBack}>
          <b><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.BACK"></FormattedMessage></b>
        </Button>
      }
      {children}
      {/* <Box>
        <Button className="btn btn-warning" type="submit"><b>
          {actionLabel || (isLastStep ? "Submit" : "Next step")}</b>
        </Button>
      </Box> */}
    </Fragment>
  );
};

export default FormWrapper;
