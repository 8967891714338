import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ActiveOfferStatusCssClasses } from "../ActiveOffersUIHelpers";
import * as actions from "../../../_redux/activeOffers/activeOffersActions";
import { useActiveOffersUIContext } from "../ActiveOffersUIContext";

const selectedActiveOffers = (entities, ids) => {
  const _activeOffers = [];
  ids.forEach((id) => {
    const activeOffer = entities.find((el) => el.id === id);
    if (activeOffer) {
      _activeOffers.push(activeOffer);
    }
  });
  return _activeOffers;
};

export function ActiveOffersUpdateStatusDialog({ show, onHide }) {
  // ActiveOffers UI Context
  const activeOffersUIContext = useActiveOffersUIContext();
  const activeOffersUIProps = useMemo(() => {
    return {
      ids: activeOffersUIContext.ids,
      setIds: activeOffersUIContext.setIds,
      queryParams: activeOffersUIContext.queryParams,
    };
  }, [activeOffersUIContext]);

  // ActiveOffers Redux state
  const { activeOffers, isLoading } = useSelector(
    (state) => ({
      activeOffers: selectedActiveOffers(state.activeOffers.entities, activeOffersUIProps.ids),
      isLoading: state.activeOffers.actionsLoading,
    }),
    shallowEqual
  );

  // if there weren't selected activeOffers we should close modal
  useEffect(() => {
    if (activeOffersUIProps.ids || activeOffersUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOffersUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for updateing activeOffer by ids
    //dispatch(actions.updateActiveOffersStatus(activeOffersUIProps.ids, status)).then(
      //() => {
        // refresh list after deletion
        // dispatch(actions.fetchActiveOffers(activeOffersUIProps.queryParams)).then(
        //   () => {
        //     // clear selections list
        //     activeOffersUIProps.setIds([]);
        //     // closing delete modal
        //     onHide();
        //   }
        // );
     // }
    //);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Status has been updated for selected activeOffers
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {activeOffers.map((activeOffer) => (
              <div className="list-timeline-item mb-3" key={activeOffer.id}>
                <span className="list-timeline-text">
                  <span
                    className={`label label-lg label-light-${
                      ActiveOfferStatusCssClasses[activeOffer.status]
                    } label-inline`}
                    style={{ width: "60px" }}
                  >
                    ID: {activeOffer.id}
                  </span>{" "}
                  <span className="ml-5">
                    {activeOffer.manufacture}, {activeOffer.model}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <select
            className={`form-control ${ActiveOfferStatusCssClasses[status]}`}
            value={status}
            onChange={(e) => setStatus(+e.target.value)}
          >
            <option value="0">Selling</option>
            <option value="1">Sold</option>
          </select>
        </div>
        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            Update Status
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
