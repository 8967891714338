/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../../_helpers";
import ReactHtmlParser from "react-html-parser";
import { Divider } from "@material-ui/core";
import { NavDropdown, NavLink } from "react-bootstrap";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchResult({ data }) {
  // console.log(data)
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted d-none">No record found</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
          {/* begin: Section */}
          {/* <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Documents
          </div> */}
          <div className="mb-10">
            {
              data && data.map((x,i)=>(
                <>
                <NavDropdown.Item
                href={x.redirectionLink}
                  className="font-weight-bold text-dark text-hover-primary"
                >
            <div key={i} className="d-flex align-items-center flex-grow-1 mb-2">
            <div className="symbol symbol-45 symbol-circle flex-shrink-0">
                <div className="symbol-label">
                  <i className="flaticon-search text-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column ml-3 mt-2 mb-2">
                  {x.title?x.title:"Test"}
                <span className="font-size-sm font-weight-bold text-muted spanDisableChild">
                  {ReactHtmlParser(x.description)}
                </span>
              </div>
            </div></NavDropdown.Item>
              <Divider/></>
              ))
                
            }
            </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
