import {createSlice} from "@reduxjs/toolkit";

const initialTopupMyAccountsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  accountItems: null,
  bestOffersForYou: null,
  loadDataValididty: null,
  topupMyAccountForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const topupMyAccountsSlice = createSlice({
  name: "topupMyAccounts",
  initialState: initialTopupMyAccountsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    myTopupMyAccountsFetched: (state, action) => {
      const { accountItems,bestOffersForYou } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.bestOffersForYou = bestOffersForYou;
      state.accountItems = accountItems;
    },
    loadedDataValidity: (state, action) => {
      const { addValidityOffers } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.loadDataValididty = addValidityOffers;
    },
    validityAdded: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
    // topupMyAccountsUpdateState
    topupMyAccountsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    offerActivated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    }
  }
});
