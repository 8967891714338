import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ActiveOffersUIHelpers";

const ActiveOffersUIContext = createContext();

export function useActiveOffersUIContext() {
  return useContext(ActiveOffersUIContext);
}

export const ActiveOffersUIConsumer = ActiveOffersUIContext.Consumer;

export function ActiveOffersUIProvider({ activeOffersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openUpdateActiveOffersStatusDialog:
      activeOffersUIEvents.openUpdateActiveOffersStatusDialog,
  };

  return (
    <ActiveOffersUIContext.Provider value={value}>
      {children}
    </ActiveOffersUIContext.Provider>
  );
}
