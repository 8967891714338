import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function Brand(props) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);
  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
          style={{alignSelf:"center"}}
      >
        
        <div className="topbar-item">
            <div className="d-flex align-items-left flex-column px-2">
              <Row>
                <Col xl={4}><img alt="profile" className="brand-prof-pic" width={48} src={props.user.pic?props.user.pic:"/media/users/default.jpg"}/></Col>
                <Col xl={8}>         <span className="text-light font-weight-bold font-size-base mr-1">
                  <FormattedMessage id="HI"></FormattedMessage> {props.user.userProfile.firstName[0]}{", "}{props.user.userProfile.lastName}
                </span><br/>
                {/* <span className="text-dark-50 font-weight-bolder font-size-base mr-3">
                  
                </span><br/> */}
                {/* <span className="symbol symbol-35 symbol-light-success">
                  <span className="symbol-label font-size-h5 font-weight-bold">
                    {props.user.fullname[0]}
                  </span>
                </span> */}
                <span className="text-light font-size-xs mr-3">
                {props.user.userProfile.msisdn}
                </span><br/>
                {/* <span className="text-light font-weight-bold font-size-base mr-1 mt-2">
                4,316 IQD
                </span> */}
                </Col>
       
              </Row>
            </div>
          </div>
        {/* begin::Logo */}
        {/* <Link to="" className="brand-logo">
        </Link> */}
        {/* end::Logo */}

        {/* {layoutProps.asideSelfMinimizeToggle && (
          <>
            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            </>
        )} */}
      </div>
      {/* end::Brand */}
      </>
  );
}
