/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {Dropdown, Image} from "react-bootstrap";
import {useHtmlClassService} from "../../../layout";
import {DropdownMenu4, DropdownCustomToggler} from "../../dropdowns";
import {KTUtil} from "../../../_assets/js/components/util";
import Silver from "./silver.png"
import { useIntl } from "react-intl";
import { useLang } from "../../../i18n";

export function MixedWidget14({ className,loyaltyDetails }) {
  const intl = useIntl()
  const lang = useLang()
function getChartOptions(layoutProps, height) {

  var options = {
    series: [parseInt((loyaltyDetails.nextLevelDetails.totalPoint/loyaltyDetails.nextLevelDetails.pendingAmount)*100)],
    chart: {
    height: 180,
    type: 'radialBar',
    // offsetY: -10
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 10,
        size: '70%',
        image: loyaltyDetails.loyaltyLevel==1?`/media/korek/Blue_Tier/gold_${lang}.png`:(loyaltyDetails.loyaltyLevel==2?
          `/media/korek/Blue_Tier/silver_${lang}.png`:`/media/korek/Blue_Tier/bronze_${lang}.png`),
        imageWidth: 96,
        // backgroung:"transparent",
        imageHeight: 96,
        imageClipped: false
      },
      startAngle: -135,
      endAngle: 135,
      dataLabels: {
        name: {
          fontSize: '16px',
          fontWeight:"bold",
          color: "white",
          offsetY: 60
        },
        value: {
          show: true,
          offsetY: 76,
          fontSize: '22px',
          color: undefined,
          formatter: function (val) {
            // return val + "%";
            // return         <Image className="level-icon" style={{ float: "center" }} src="/media/korek/silver.png"></Image>
          }
        }
      }
    }
  },
  fill: {
  colors: ['#fff', '#B32824']
    // type: 'gradient',
    // gradient: {
    //     shade: 'light',
    //     shadeIntensity: 0.15,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 50, 65, 91]
    // },
  },
  labels: [loyaltyDetails.loyaltyLevel==1?intl.formatMessage({id:"BLUE.FAQ.TABLE.ROW31"}):(loyaltyDetails.loyaltyLevel==2?intl.formatMessage({id:"BLUE.FAQ.TABLE.ROW21"}):intl.formatMessage({id:"BLUE.FAQ.TABLE.ROW11"}))],
  stroke: {
    lineCap: 'round'
  },
  };
  return options;
}

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBaseSuccess: objectPath.get(
          uiService.config,
          "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
          uiService.config,
          "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, 'height'));
    const options = getChartOptions(layoutProps, height);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
        <div className="flex-grow-1" style={{position:"absolute",width:"100%"}}>
          <div id="kt_mixed_widget_14_chart" style={{height: "auto"}}></div>
        </div>
  );
}


