import { useLang } from "../i18n";
import { toAbsoluteUrl } from "./AssetsHelpers";

export const GetLogo = (theme) => {
  const lang = useLang();

  let result = "";

  switch (theme) {
    case "dark":
      switch (lang) {
        case "ar":
          result = "Logo_Ar.svg";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "ku":
          result = "Logo_Kur.svg";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "en":
          result = "Korek_En.svg";
          return toAbsoluteUrl(`/media/logos/${result}`);
      }
    case "white":
      switch (lang) {
        case "ar":
          result = "Logo_Ar_White.svg";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "ku":
          result = "Logo_Kur_White.svg";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "en":
          result = "Korek_En_White.svg";
          return toAbsoluteUrl(`/media/logos/${result}`);
      }
    default:
  }
};
export const GetLogoBusiness = (theme) => {
  const lang = useLang();

  let result = "";

  switch (theme) {
    case "dark":
      switch (lang) {
        case "ar":
          result = "korek-business-logo-dark-ar.png";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "ku":
          result = "korek-business-logo-dark-ku.png";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "en":
          result = "korek-business-logo-dark-en.png";
          return toAbsoluteUrl(`/media/logos/${result}`);
      }
    case "white":
      switch (lang) {
        case "ar":
          result = "korek-business-logo-white-ar.png";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "ku":
          result = "korek-business-logo-white-ku.png";
          return toAbsoluteUrl(`/media/logos/${result}`);
        case "en":
          result = "korek-business-logo-white-en.png";
          return toAbsoluteUrl(`/media/logos/${result}`);
      }
    default:
  }
};
