import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import AccountInformation from "./AccountInformation";
import { ProfileOverview } from "./ProfileOverview";
import ChangePassword from "./ChangePassword";
import PersonaInformation from "./PersonaInformation";
import EmailSettings from "./EmailSettings";
import { ProfileCard } from "./components/ProfileCard";
import "../../../_metronic/_assets/sass/pages/my-account/my-account.scss"
import { useDispatch } from "react-redux";
import * as actions from "./_redux/Profile/profileActions";
import Controls from "./Controls";

export default function UserProfilePage() {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("User profile");
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getProfile());
  }, []);

  return (
    <div className="my-account">
      <ProfileCard></ProfileCard>
      <div style={{paddingTop:"25px"}} className="">
        <Switch>
          <Redirect
            from="/my-korek/my-account"
            exact={true}
            to="/my-korek/my-account/my-profile"
          />
          <Route
            path="/my-korek/my-account/my-profile"
            component={PersonaInformation}
          />
          <Route
            path="/my-korek/my-account/controls"
            component={Controls}
          />
          <Route
            path="/my-korek/my-account/connected-devices"
            component={AccountInformation}
          />
        </Switch>
      </div>
    </div>
  );
}
