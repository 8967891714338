import React, { useMemo } from "react";
import { useTopupMyAccountsUIContext } from "./TopupMyAccountsUIContext";
import "../../../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { Divider } from "@material-ui/core";
import { fetchMyTopupMyAccounts } from "../../_redux/topupMyAccounts/topupMyAccountsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Image, Row, Col, Button, Card } from "react-bootstrap";
import "../../../../../_metronic/_assets/sass/pages/my-korek/topup-my-account.scss";
import { FormattedMessage } from "react-intl";

const services = [
  {
    name: "Balance",
    amount: "4,316 IQD",
    btnName: "Add Balance",
  },
  {
    name: "Data",
    amount: "5/12 GB",
    btnName: "Add Data",
  },
  {
    name: "Voice",
    amount: "209/300 Min",
    btnName: "Add Voice",
  },
  {
    name: "Validity",
    amount: "10/40 Days",
    btnName: "Add Validity",
  },
];
const offers = [
  {
    name: "Kulish Kafi Package",
    amount: "10,000 IQD",
    src:"/media/korek/tp-offer1.png"
  },
  {
    name: "Youth offer",
    amount: "10,000 IQD",
    src:"/media/korek/tp-offer2.png"
  },
  {
    name: "Infinite",
    amount: "10,000 IQD",
    src:"/media/korek/tp-offer3.png"
  },
];


export function TopupMyAccountsCard() {
  const topupMyAccountsUIContext = useTopupMyAccountsUIContext();
  const dispatch = useDispatch()
  const topupMyAccountsUIProps = useMemo(() => {
    return {
      ids: topupMyAccountsUIContext.ids,
      queryParams: topupMyAccountsUIContext.queryParams,
      setQueryParams: topupMyAccountsUIContext.setQueryParams,
      openUpdateTopupMyAccountsStatusDialog:
        topupMyAccountsUIContext.openUpdateTopupMyAccountsStatusDialog,
        openValidity:
        topupMyAccountsUIContext.openValidity,
        openActivateBestOfferForYou:
        topupMyAccountsUIContext.openActivateBestOfferForYou,
    };
  }, [topupMyAccountsUIContext]);

  
  useEffect(() => {  
    dispatch(fetchMyTopupMyAccounts())
  },[]);
  
  const { currentState } = useSelector(
    (state) => ({ currentState: state.topupMyAccounts }),
    shallowEqual
  );
  const { accountItems,bestOffersForYou } = currentState;
  return (
    <div className="topup-my-account mx-3 pb-5">
      <Row className="gap-2">
        {accountItems && accountItems.map((x, i) => (
          <Col
            className={
              i == 0
                ? "box mr-2 my-2"
                : i == accountItems.length - 1
                ? "box ml-2 my-2"
                : "box mx-2 my-2"
            }
          >
            <Image className="mx-auto" src={"/media/korek/topup-"+x.idType+".png"}></Image>
            <br />
            <p className="upper">{x.itemName}</p>{" "}
            <p className="lower">{x.itemValue}</p>
            {/* <Button className="btn btn-small btn-warning"> */}
              <a href={x.idType=="4" ? "/my-korek/validity":x.idType=="1" ?"/my-korek/recharge-line":""} className="btn btn-small btn-warning">
              <b><FormattedMessage id="TOP_UP.BEST_OF_FOR_YOU.ADD"></FormattedMessage>{" "+x.itemName}</b></a>
            {/* </Button> */}
          </Col>
        ))}
      </Row>
      <div className="my-5 offer mx-2">
        <h4>
          <b><FormattedMessage id="TOP_UP.BEST_OF_FOR_YOU"></FormattedMessage></b>
        </h4>
        <Row className="py-5">
          {bestOffersForYou && bestOffersForYou.list.map((x) => (
            <Col className="py-5" xl={6} key={x.id}>
              <Card style={{ backgroundColor: "#F8F8F8" }}>
                <Card.Img variant="top" src={x.imageUrl?x.imageUrl:"/media/korek/tp-offer3.png"} />
                <Card.Body className="p-5 text-dark">
                  <Card.Title className="">
                    <Row>
                      <Col style={{whiteSpace:"nowrap"}} className="text-dark">
                        {x.name}
                      </Col>
                      <Col className="text-primary text-right">
                        <b>{x.price}</b>
                      </Col>
                    </Row>
                  </Card.Title>
                  <Card.Text>
                    <Row>
                      <Col>
                        <Button onClick={()=>topupMyAccountsUIProps.openActivateBestOfferForYou(x.idData)} className="btn btn-warning">
                          <b><FormattedMessage id="TOP_UP.ACTIVATE_OFFER"></FormattedMessage></b>
                        </Button>
                      </Col>
                      {/* <Col>
                        <Button className="btn btn-outline-primary">
                          <b>View Details</b>
                        </Button>
                      </Col> */}
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {bestOffersForYou?.list.length == 0 && <Row className=""><FormattedMessage id="NO_DATA_FOUND">
            </FormattedMessage></Row>}
        </Row>
        {bestOffersForYou && bestOffersForYou.count>3 &&<h6 className="text-primary">
          <b><FormattedMessage id="TOP_UP.LOAD_MORE_OF"></FormattedMessage></b>
        </h6>}
      </div>
    </div>
  );
}
