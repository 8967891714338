import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import { date } from "yup";
import { useIntl } from "react-intl";

export function DatePickerField({ ...props }) {
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors && props.withValidation) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const intl = useIntl();
  
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        // startDate={Moment().toDate()}
        // minDate={dt ? dt : new Date()}
        maxDate={props.maxDate}
        minDate= {props.minDate}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        dateFormat="yyyy-MM-dd"
        // placeholderText={intl.formatMessage({id:"COMMON.ENTER_DATE"})}
        selected={(field.value && new Date(field.value)) || null}
        onChange={val => {
          setFieldValue(field.name, val);
          props.changeVal && props.changeVal(field.name, val);
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="feedback">
          {/* Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format */}
        </div>
      )}
    </>
  );
}
