import axiosdotnet from "./axiosdotnet";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { KTCookie } from "../_metronic/_assets/js/components/cookie";
import { Router } from "react-router";
import { actionTypes } from "../app/modules/Auth/_redux/authRedux";
// import { refreshToken,logout } from "../app/modules/Auth/_redux/authCrud";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { sha256 } from "js-sha256";
import { useSelector } from "react-redux";
import { Logout } from "../app/modules/Auth";
import React from "react";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Close } from "@material-ui/icons";
// import Snackbar from "@material-ui/core/Snackbar";
// import Slide from "@material-ui/core/Slide";
// import Alert from "@material-ui/lab/Alert";

// function randomDate(start, end) {
//   return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).getTime();
// }

export const sendRequestDotNet = async (
  link,
  data = {},
  headers = {},
  csv = false,
  method = "POST"
) => {
  // const d = randomDate(new Date(2012, 0, 1), new Date());
  let contentType = "application/json";
  if (data instanceof FormData) contentType = "multipart/form-data";
  // console.log(JSON.parse(localStorage.getItem("i18nConfig")).selectedLang);
  // console.log(JSON.parse(localStorage.getItem('persist:v713-demo1-auth')))
  headers = {
    ...headers,
    "content-type": contentType,
    IdLanguage: localStorage.getItem("i18nConfig")
      ? JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en"
        ? 1
        : JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ar"
          ? 2
          : 3
      : 1,
    idhandset: 4,
    timestamp: Date.now(),
    idusertype: headers.idusertype
      ? headers.idusertype
      : KTCookie.getCookie("idUserType") != undefined
        ? KTCookie.getCookie("idUserType")
        : "3",
    // idusertype:JSON.parse(localStorage.getItem('persist:v713-demo1-auth'))==null?"1":"2",
    Token: KTCookie.getCookie("token") ? KTCookie.getCookie("token") :  KTCookie.getCookie("idGuest") ?KTCookie.getCookie("idGuest"):"",
    iddevice: KTCookie.getCookie("did") ? KTCookie.getCookie("did") : "",
  };

  // let uId=getCookie("user_id");
  // let uAu = getCookie("user_auth");
  // if(uAu!=undefined)
  // {
  // var ss = KTCookie.getCookie("jwt");

  // console.log(JSON.parse(localStorage.getItem('persist:v726-demo1-auth')));
  // console.log(link);
  // var ss = localStorage.getItem('ab');
  // if (ss == undefined) {
  //   ss = "";
  // }
  headers = {
    ...headers,
    post: {
      // uid:"e9280844-1cb6-4336-a62c-b72633ad6b06",
      // did:"071bf99b-452e-4776-b203-4706d9ac846a",
      // idhandset:1,
      // idsourceapp:1,
      //Token:uId.replace(/\s/g, '').replace('+', ''),
      // Authorization: "Bearer " + KTCookie.getCookie("tt"),
      // IdLanguage: KTCookie.getCookie("language") ? KTCookie.getCookie("language") : 1,
      // IdChannel: 5,
      // UType: (link.toLowerCase() == "companyprofile/add") ? 2 : (KTCookie.getCookie("uu") ? KTCookie.getCookie("uu") : 1),
      // Token: KTCookie.getCookie("cc") ? KTCookie.getCookie("cc") : ""
      // Authorization: "Bearer "
    },
  };

  //     //headers.post['Token'] =
  // }
  let axiosInst = null;
  var l = link
  if (method === "POST")
    axiosInst = axiosdotnet.post(link, data, { headers: headers });
  else axiosInst = axiosdotnet.get(link, { headers: headers });
  var is401 = false;
  var canRT = false;
  var inst = await axiosInst
    .then((response) => {
      // console.log(response);
      let MsgResponse = "Something went wrong! Please try again";
      if (typeof response !== "undefined" && csv) {
        var header = response.headers;
        var fileName = header["content-disposition"].split("''")[1];
        var blob = new Blob([response.data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        return {
          error: false,
          data: response.data,
        };
      }
      if (typeof response.data !== "undefined") {
        //  console.log(response.data)
        if (typeof response.data.statusCode !== "undefined") {
          if (typeof response.data.statusCode.message !== "undefined") {
            MsgResponse = response.data.statusCode.message;
          }
          if (
            typeof response.data.statusCode.code !== "undefined" &&
            (response.data.statusCode.code === 0 ||
              response.data.statusCode.code === 105 ||
              response.data.statusCode.code === 122 ||
              response.data.statusCode.code === 690 ||
              response.data.statusCode.code === 700 ||
              response.data.statusCode.code === 701 ||
              response.data.statusCode.code === 702 ||
              response.data.statusCode.code === 803 ||
              response.data.statusCode.code === 804 ||
              response.data.statusCode.code === 805 ||
              response.data.statusCode.code === 810 ||
              response.data.statusCode.code === 950 ||
              response.data.statusCode.code === 959 ||
              response.data.statusCode.code === 974 ||
              response.data.statusCode.code === 975 ||
              response.data.statusCode.code === 806)
          ) {
            return {
              error: false,
              data: response.data,
            };
          } else if (response.data.statusCode.code === 900) {
            is401 = true;
          } else if (response.data.statusCode.code === 11) {
            // axiosdotnet
            //   .post("User/Logout", {}, { headers: headers })
            //   .then((response) => {
            //     window.location.replace("/logout");
            //   });
          } else if (response.data.statusCode.code === 1) {
            // console.log("code 1")
            // axiosdotnet
            //   .post("User/Logout", {}, { headers: headers })
            //   .then((response) => {
            //     window.location.replace("/logout");
            //   });
            enqueueSnackbar(response.data.statusCode.message, {
              variant: "error"
            });
          } else if (
            response.data.statusCode.code === 17 ||
            response.data.statusCode.code === 16
          ) {

            const cookies = document.cookie.split(";");

              
            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i];
              const eqPos = cookie.indexOf("=");
              const name =
                eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie =
                name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            l != "Registration/Declaration" && l != "Registration/SkipLogin" && window.location.replace("/");

            // KTCookie.deleteCookie("did")
            // if (!KTCookie.getCookie("token")) {
            //   KTCookie.deleteCookie("token")
            //  // window.location.reload();
            // //  l != "Profile/Logout" && window.location.replace("/logout");
            // }
            // else {
            //   KTCookie.deleteCookie("token")
            //   // window.location.replace("/");
            // }
          }
        }

        //// special if no statusCode ////
        if (typeof response.data.code !== "undefined") {
          if (typeof response.data.message !== "undefined") {
            MsgResponse = response.data.message;
          }

          if (
            typeof response.data.code !== "undefined" &&
            response.data.code === 0
          ) {
            return {
              error: false,
              data: response.data,
            };
          } else if (response.data.code === 17) {
            toastMe(true, MsgResponse);
            // window.location.replace("/error/error-unauthorized")
            // KTCookie.setCookie("AD", true);
            const cookies = document.cookie.split(";");
            // axiosdotnet
            //   .post("Profile/Logout", {}, { headers: headers })
            //   .then((response) => {
            //     for (let i = 0; i < cookies.length; i++) {
            //       const cookie = cookies[i];
            //       const eqPos = cookie.indexOf("=");
            //       const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            //       document.cookie =
            //         name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            //     }
            //     window.location.replace("/logout");
            //   });
          } else if (response.data.statusCode.code == 1) {
            toastMe(true, response.data.statusCode.message);
          } else if (response.data.statusCode.code == 900) {
            is401 = true;
          }
        }
        //// special if no statusCode ////
      }
      // if (response.code == 1) {
      //   toastMe(true, response.message);
      // }
      // if (response.data.statusCode.code == 1) {
      //   toastMe(true, response.data.statusCode.message);
      // }
      //
      // toastMe(true, MsgResponse,"warning");
      return {
        error: true,
        message: MsgResponse,
      };
    })
    .catch((error) => {
      console.log(error.message);
      let MsgResponse = "Something went wrong! Please try again";
      if (error.response.status === 401) {
        enqueueSnackbar(error.message?error.message:MsgResponse, {
          variant: "error"
        });
        // is401 = true;
      } else
        enqueueSnackbar(error.message?error.message:MsgResponse, {
          variant: "error"
        });

      return {
        error: true,
        message: error.message?error.message:MsgResponse,
        data: data,
      };
    });

  //return inst;
  //console.log(is401);
  if (!is401) {
    return inst;
  } else if (!canRT) {
    // setTimeout(() => {
    await axiosdotnet
      .post("Profile/RefreshToken", {}, { headers: headers })
      // await refreshToken()
      .then((response) => {
        // console.log("RefreshToken::",response);
        if (response.data.statusCode.code === 0) {
          // KTCookie.setCookie("token", "");
          KTCookie.setCookie("token", response.data.token);
          canRT = true;
          // let currentPath = window.location.pathname;
          // useHistory().push(currentPath);
          // auth.actions.refreshToken(
          //   response.data.user.token,
          //   response.data.roles,
          //   response.data.user,
          //   response.data.seasons
          // );
          return {
            error: false,
            data: response.data,
          };
        } else if (
          response.data.statusCode.code === 17 ||
          response.data.statusCode.code === 16 || response.data.statusCode.code === 900
        ) {
          const cookies = document.cookie.split(";");

              
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name =
              eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie =
              name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
          l != "Registration/Declaration" && l != "Registration/SkipLogin" && window.location.replace("/");

          // toastMe(true, MsgResponse);
          // window.location.replace("/error/error-unauthorized")
          // KTCookie.setCookie("AD", true);

          // const cookies = document.cookie.split(";");
          // axiosdotnet
          //   .post("Profile/Logout", {}, { headers: headers })
          //   .then((response) => {
          //     for (let i = 0; i < cookies.length; i++) {
          //       const cookie = cookies[i];
          //       const eqPos = cookie.indexOf("=");
          //       const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          //       document.cookie =
          //         name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          //     }
          //     window.location.replace("/logout");
          //   });

          KTCookie.deleteCookie("did")
          if (!KTCookie.getCookie("token")) {
           // l != "Profile/Logout" && window.location.replace("/logout");
          }
          else {
            KTCookie.deleteCookie("token")
            // window.location.replace("/");
          }
          // return (<Logout/>);
          // axiosdotnet
          //   .post("Profile/Logout", {}, { headers: headers })
          //   .then((response) => {
          //   });
        }
        // else {
        // const cookies = document.cookie.split(";");
        // axiosdotnet
        //   .post("Profile/Logout", {}, { headers: headers })
        //   .then((response) => {
        //     for (let i = 0; i < cookies.length; i++) {
        //       const cookie = cookies[i];
        //       const eqPos = cookie.indexOf("=");
        //       const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //       document.cookie =
        //         name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        //     }
        //     window.location.replace("/logout");
        //   });
        // window.location.replace("/logout")
        // useHistory().push("/logout");
        // console.log(response);
        // redirect(context, "/login");
        // }
      })
      .catch((error) => {
        let MsgResponse = "Something went wrong! Please try again";
        // console.log(error);

        //  window.location.replace("/logout")
        return {
          error: true,
          message: MsgResponse,
          data: data,
        };
        // useHistory().push("/logout");
        // redirect(context, "/login");
      });

    // },1000)
    let contentType = "application/json";
    if (data instanceof FormData) contentType = "multipart/form-data";

    //////
    // console.log(KTCookie.getCookie("token"))
    headers = {
      ...headers,
      Token: KTCookie.getCookie("token") ? KTCookie.getCookie("token") : KTCookie.getCookie("idGuest") ,
    };
    // headers.Token = KTCookie.getCookie("token")
    // console.log(headers.Token)

    // let uAu = getCookie("user_auth");
    // if(uAu!=undefined)
    // {
    // headers = {
    // ...headers,
    // post:{
    //Token:uId.replace(/\s/g, '').replace('+', ''),
    // Authorization: "Bearer " + getCookie("user_auth")
    // }
    // }
    //headers.post['Token'] =
    // }

    let axiosInst = null;
    if (method === "POST")
      axiosInst = axiosdotnet.post(link, data, { headers: headers });
    else axiosInst = axiosdotnet.get(link, { headers: headers });

    return axiosInst
      .then((response) => {
        let MsgResponse = "Something went wrong! Please try again";
        if (typeof response.data !== "undefined") {
          if (typeof response.data.statusCode !== "undefined") {
            if (typeof response.data.statusCode.message !== "undefined") {
              MsgResponse = response.data.statusCode.message;
            }
            if (
              typeof response.data.statusCode.code !== "undefined" &&
              response.data.statusCode.code === 0
            ) {
              return {
                error: false,
                data: response.data,
              };
            }
            else {
              const cookies = document.cookie.split(";");

              
              for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf("=");
                const name =
                  eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie =
                  name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
              l != "Registration/Declaration" && l != "Registration/SkipLogin" && window.location.replace("/");
              //window.location.reload();//replace("/logout");


              // axiosdotnet
              //   .post("Profile/Logout", {}, { headers: headers })
              //   .then((response) => {
              //     for (let i = 0; i < cookies.length; i++) {
              //       const cookie = cookies[i];
              //       const eqPos = cookie.indexOf("=");
              //       const name =
              //         eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              //       document.cookie =
              //         name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              //     }
              //     //window.location.reload();//replace("/logout");
              //   });

            }
          }

          //// special if no statusCode ////
          if (typeof response.data !== "undefined") {
            if (typeof response.data.statusCode !== "undefined") {
              // console.log(response.data)
              if (typeof response.data.statusCode.message !== "undefined") {
                MsgResponse = response.data.statusCode.message;
              }
              if (
                typeof response.data.statusCode.code !== "undefined" &&
                response.data.statusCode.code === 0
              ) {
                return {
                  error: false,
                  data: response.data,
                };
              } else if (response.data.statusCode.code === 11) {
                // axiosdotnet.post("User/Logout", {}, { headers: headers })
                //  .then((response) => {
                //       window.location.replace("/logout")
                //   }
                //  )
              } else if (response.data.statusCode.code === 17) {
                // toastMe(true, MsgResponse);
                // // window.location.replace("/error/error-unauthorized")
                // KTCookie.setCookie("AD", true);
                // window.location.replace("/logout");

                // axiosdotnet.post("User/Logout", {}, { headers: headers })
                //  .then((response) => {
                //       window.location.replace("/logout")
                //   }

                //  )

                const cookies = document.cookie.split(";");

                for (let i = 0; i < cookies.length; i++) {
                  const cookie = cookies[i];
                  const eqPos = cookie.indexOf("=");
                  const name =
                    eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                  document.cookie =
                    name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
                l != "Registration/Declaration" && l != "Registration/SkipLogin" &&  window.location.replace("/");


                // axiosdotnet
                //   .post("Profile/Logout", {}, { headers: headers })
                //   .then((response) => {
                //     for (let i = 0; i < cookies.length; i++) {
                //       const cookie = cookies[i];
                //       const eqPos = cookie.indexOf("=");
                //       const name =
                //         eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                //       document.cookie =
                //         name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                //     }
                //    // window.location.replace("/logout");
                //   });
              }
            }

            //// special if no statusCode ////
            if (typeof response.data.code !== "undefined") {
              if (typeof response.data.message !== "undefined") {
                MsgResponse = response.data.message;
              }

              if (
                typeof response.data.code !== "undefined" &&
                response.data.code === 0
              ) {
                return {
                  error: false,
                  data: response.data,
                };
              }
            }
            //// special if no statusCode ////
          }

          //// special if no statusCode ////
        }

        // toastMe(true, MsgResponse,"warning");
        return {
          error: true,
          message: MsgResponse,
        };
      })
      .catch((error) => {
        //console.log(error);
        let MsgResponse = "Something went wrong! Please try again";
        if (error.response.status === 401) {
          // console.log(error);
          //  window.location.replace("/logout")
          // redirect(context, "/login");
        } else toastMe(true, MsgResponse);
        return {
          error: true,
          message: MsgResponse,
          data: data,
        };
      });
  }
};

export const action = snackbarId => (
  <>
    <Close className='d-pointer' onClick={() => { closeSnackbar(snackbarId) }}>
      Dismiss
    </Close>
  </>
);
// export const toastMe = WrappedComponent => {
//   return props => { /** error | warning | info */

//     const [open, setOpen] = useState(false);
//     const [message, setMessage] = useState("I'm a custom snackbar");
//     const [duration, setDuration] = useState(2000);
//     const [severity, setSeverity] = useState(
//       "success"
//     );
//     const showMessage = (message, severity = "success", duration = 2000) => {
//       setMessage(message);
//       setSeverity(severity);
//       setDuration(duration);
//       setOpen(true);
//     };

//     const handleClose = (event, reason) => {
//       if (reason === "clickaway") {
//         return;
//       }
//       setOpen(false);
//     };

//     return (
//       <>
//         <WrappedComponent {...props} snackbarShowMessage={showMessage} />
//         <Snackbar
//           anchorOrigin={{
//             vertical: "bottom",
//             horizontal: "center"
//           }}
//           autoHideDuration={duration}
//           open={open}
//           onClose={handleClose}
//           TransitionComponent={Slide}
//         >
//           <Alert variant="filled" onClose={handleClose} severity={severity}>
//             {message}
//           </Alert>
//         </Snackbar>
//       </>
//     );
//   };
// };

export function toastMe(show = true, message = "", type = "", position = "") {
  if (!show) {
    toast.dismiss();
  } else {
    let p = toast.POSITION.TOP_CENTER;
    switch (position) {
      case "bl":
        p = toast.POSITION.BOTTOM_LEFT;
        break;
      case "tr":
        p = toast.POSITION.TOP_RIGHT;
        break;
      case "br":
        p = toast.POSITION.BOTTOM_RIGHT;
        break;
      case "tc":
        p = toast.POSITION.TOP_CENTER;
        break;
      case "tl":
        p = toast.POSITION.TOP_LEFT;
        break;
    }
    let tp = toast.TYPE.DEFAULT;
    switch (type) {
      case "dark":
        tp = toast.TYPE.DARK;
        break;
      case "error":
        tp = toast.TYPE.ERROR;
        break;
      case "info":
        tp = toast.TYPE.INFO;
        break;
      case "success":
        tp = toast.TYPE.SUCCESS;
        break;
      case "warning":
        tp = toast.TYPE.WARNING;
        break;
    }

    const options = {
      // onOpen: props => console.log(props.foo),
      // onClose: props => console.log(props.foo),
      // autoClose: 6000,
      // closeButton: FontAwesomeCloseButton,
      type: tp,
      position: p,
      // hideProgressBar: false,
      // pauseOnHover: true,
      // transition: MyCustomTransition,
      // progress: 0.2
    };
    toast(message, options);
  }
}

export const sendRequestImg = async (
  link,
  data = {},
  headers = {},
  method = "POST"
) => {
  let contentType = "application/json";
  if (data instanceof FormData) contentType = "multipart/form-data";

  headers = {
    ...headers,
    "content-type": contentType,
    IdLanguage: KTCookie.getCookie("language")
      ? KTCookie.getCookie("language")
      : 1,
  };
  let axiosInst = null;
  if (method === "POST")
    axiosInst = axiosdotnet.post(link, data, { headers: headers });
  else axiosInst = axiosdotnet.get(link, { headers: headers });

  var inst = await axiosInst
    .then((response) => {
      let MsgResponse = "Something went wrong! Please try again";
      if (typeof response.status !== "undefined") {
        if (response.status === 0 || response.status === 200) {
          return {
            error: false,
            data: response.data,
          };
        }
      }

      return {
        error: true,
        message: MsgResponse,
      };
    })
    .catch((error) => {
      return {
        error: true,
        message: "msg",
        data: data,
      };
    });

  return inst;
};

export const download = (e, name) => {
  // console.log(e);
  // console.log(e);
  let href = e.target ? e.target.href : e;
  toastMe(true, "Loading...", "warning");
  // console.log(href);
  fetch(href, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      toastMe(false, "Loading...", "warning");
      if (response.headers.get("Content-Type").split("/")[1] == "msword") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download doc", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".doc"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get("Content-Type").split("/")[1] == "png") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get("Content-Type").split("/")[1] == "pdf") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get("Content-Type").split("/")[1] == "jpg") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".JPG"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get("Content-Type").split("/")[1] == "jpeg") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".jpeg"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      if (response.headers.get("Content-Type").split("/")[1] == "csv") {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }

      if (
        response.headers.get("Content-Type").split("/")[1] == "vnd.ms-excel"
      ) {
        if (response.status == 200) {
          toastMe(true, "Success", "success");
        } else if (response.status == 204) {
          toastMe(true, "No Content", "warning");
        } else toastMe(true, "Cannot Download Attachment", "error");
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", GUID() + ".xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }

      //   console.log(response)
      // console.log(response.headers.get('Content-Type').split("/")[1])
      // response.arrayBuffer().then(function(buffer) {
      //   const url = window.URL.createObjectURL(new Blob([buffer]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", GUID()); //or any other extension
      //   document.body.appendChild(link);
      //   link.click();
      // });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const downloadJpg = (e, name) => {
  // console.log(e.target.href);
  fetch(e, {
    method: "GET",
    // mode: 'no-cors',
    headers: {
      //   'Access-Control-Allow-Origin':"*",
      //  "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
      //  'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      //   console.log(response)
      if (response.status == 200) {
        toastMe(true, "Success", "success");
      } else if (response.status == 204) {
        toastMe(true, response.statusText, "warning");
      } else toastMe(true, "Cannot Download Image", "error");
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", GUID() + ".jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      toastMe(true, "Cannot Download Image", "error");
      console.log(err);
    });
};
export const downloadPdf = (e, name) => {
  // console.log(e.target.href);
  fetch(e, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      //   console.log(response)
      if (response.status == 200) {
        toastMe(true, "Success", "success");
      } else if (response.status == 204) {
        toastMe(true, response.statusText, "warning");
      } else toastMe(true, "Cannot Download PDF", "error");
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", GUID() + ".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      toastMe(true, "Cannot Download PDF", "error");
      console.log(err);
    });
};

export const nullChecker = (cell) => (!cell ? "N/A" : cell);
var nf = new Intl.NumberFormat();
//   nf.format(number);
export const parseNumber = (cellContent) =>
  !cellContent ? 0 : nf.format(parseInt(cellContent));

export const Encrypt = (msg) => {
  return sha256("xxxxxxxx" + msg);
};

export const GUID = () => {
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const ConvertArrayToCommaSeperated = (array) => {
  return array.join(", ");
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const formatUTC = (dateInt, addOffset = false) => {
  let date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
    return date;
  } else {
    const offset = addOffset
      ? date.getTimezoneOffset()
      : -date.getTimezoneOffset();
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + offset * 60000);
    return offsetDate;
  }
};

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

export function inWords(num) {
  if ((num = num.toString()).length > 9) return "overflow";
  var n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      ""
      : "";
  return str;
}

export function deviceCheck() {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") != -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1 ||
    !!document.documentMode == true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
}

export const phoneRegExp = /[964]{3}(7[0123456789][0123456789])/;
export const phoneRegExp1 = /^075(1[0-9]|0[0-9])\d{7}$/;
export const phoneRegExp2 = /^(?:96475[01]\d{7}|075[01]\d{7})$/;

