import axios from "axios";
import { sendRequestDotNet } from "../../../../../_utils/Axios";

export const CUSTOMERS_URL = "api/activeVasOffers";
export const OFFER_URL = "Offer";


export function findMyActiveVasOffers() {
  return sendRequestDotNet(`${OFFER_URL}/MyActiveVasOffers`, {});
}
export function activeVasOffer(queryParams) {
  return sendRequestDotNet(`${OFFER_URL}/VasOffers/Activate`, queryParams);
}

// UPDATE Status
export function updateStatusForActiveVasOffers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForActiveVasOffers`, {
    ids,
    status
  });
}
