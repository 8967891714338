import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import UserProfilePage from "../../UserProfile/UserProfilePage";
import { TopupMyAccountPage } from "./topUpMyAccount/TopupMyAccountPage";
import { ActiveOffersPage } from "./activeOffers/ActiveOffersPage";
import { ActiveVasOffersPage } from "./activeVasOffers/ActiveVasOffersPage";
import { SupportPage } from "./support/SupportPage";
import { MyBillsPage } from "./myBills/MyBillsPage";
import { RechargeLinePage } from "./rechargeLine/RechargeLinePage";
// import { BlueProgramPage } from "./blueProgram/BlueProgramPage";
import { lazy } from "react";
import { ValidityPage } from "./topUpMyAccount/ValidityPage";


const BlueProgramPage = lazy(() =>
  import("./blueProgram/BlueProgramPage")
);

export default function MyKorekPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          // <Redirect
          //   exact={true}
          //   from="/my-korek/e-commerce"
          //   to="/my-korek/e-commerce/customers"
          // />
        }
        {/* <ContentRoute path="/my-korek/e-commerce/customers" component={CustomersPage} />
        <ContentRoute path="/my-korek/e-commerce/products/new" component={ProductEdit} />
        <ContentRoute
          path="/my-korek/e-commerce/products/:id/edit"
          component={ProductEdit}
        /> */}

        {/* <ContentRoute path="/my-korek/e-commerce/products" component={ProductsPage} /> */}
        <ContentRoute path="/my-korek/topup-my-account" component={TopupMyAccountPage} />
        <ContentRoute path="/my-korek/validity" component={ValidityPage} />
        <ContentRoute path="/my-korek/active-offers" component={ActiveOffersPage} />
        <ContentRoute path="/my-korek/active-vas-offers" component={ActiveVasOffersPage} />
        <ContentRoute path="/my-korek/support" component={SupportPage} />
        <ContentRoute path="/my-korek/my-bills" component={MyBillsPage} />
        <ContentRoute path="/my-korek/recharge-line" component={RechargeLinePage} />
        <ContentRoute path="/my-korek/blue-program" component={BlueProgramPage} />
        {/* <ContentRoute path="/my-korek/my-account" component={UserProfilePage} /> */}
      </Switch>
    </Suspense>
  );
}
