/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState, useCallback, useLayoutEffect } from "react";
import objectPath from "object-path";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import { Col, Row, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { checkIsActive } from "../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";
import { useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { getBreadcrumbsAndTitle } from "../../_core/MetronicSubheader";

export function SubHeaderBusiness() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();

  const businessNavbarItems = useSelector(
    (state) => state.profileSlice.businessNavbarItems,
    shallowEqual
  );

  // Memoize layoutProps to avoid unnecessary recalculations
  const layoutProps = useMemo(() => ({
    config: uiService.config,
    subheaderMobileToggle: objectPath.get(
      uiService.config,
      "subheader.mobile-toggle"
    ),
    subheaderCssClasses: uiService.getClasses("subheader", true),
    subheaderContainerCssClasses: uiService.getClasses(
      "subheader_container",
      true
    ),
  }), [uiService]);

  const [show, setShow] = useState("");

  // Toggle dropdown visibility
  const handleDropdownToggle = useCallback((id) => {
    setShow((prev) => (prev === id ? "" : id));
  }, []);

  // Navigate to a different path
  const handleLinkClick = useCallback((path) => {
    if (path) {
      history.push(path);
    }
  }, [history]);

  return (
    <div
      id="kt_subheader"
      style={{ width: "100%", right: "auto" }}
      className={`subheader py-2 py-lg-4 centerFixed subheader-business-fixed logo-subheader-mobile ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} maxWidthCont d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        <Navbar className="navbar-dark" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {businessNavbarItems && businessNavbarItems.map((x) =>
                x.subsections.length > 0 ? (
                  <NavDropdown
                    key={x.id}
                    title={x.title}
                    className="pr-5 navbar-not dropdown-header-animation"
                    id={`offcanvasNavbarDropdown-expand-xl`}
                    onMouseEnter={() => handleDropdownToggle(x.id)}
                    onMouseLeave={() => handleDropdownToggle(x.id)}
                    show={show === x.id}
                    style={{paddingTop:"1rem"}}
                  >
                    <Row
                      style={{ alignItems: "baseline" }}
                      className="padding-sub justify-content-start"
                    >
                      {x.subsections.map((y, i) => {
                        const redirectionLink = y.redirectionLink || "#";
                              // const redirectionLink = y.id == "86881959-43a7-4876-a0dc-80246a0f0625"?"/business/vip/elite-club":y.redirectionLink || "#";
                        return (
                          <Col key={i} xl={3}>
                            <NavDropdown.Item
                              as={NavLink}
                              to={redirectionLink}
                              activeClassName="menu-item-active"
                              onClick={() => {
                                handleLinkClick(redirectionLink)
                                // handleDropdownToggle("")
                              }}
                            >
                              <b>{y.title}</b>
                            </NavDropdown.Item>
                            {y.subsections && y.subsections.map((z, index) => {
                              const subsectionLink = z.redirectionLink || "#";
                              return (
                                <NavDropdown.Item
                                  key={index}
                                  as={NavLink}
                                  to={subsectionLink}
                                  activeClassName="menu-item-active"
                                  onClick={() => 
                                    {
                                      handleLinkClick(subsectionLink)
                                      // handleDropdownToggle("")
                                    }}
                                >
                                  {z.title}
                                </NavDropdown.Item>
                              );
                            })}
                          </Col>
                        );
                      })}
                    </Row>
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    key={x.id}
                    as={NavLink}
                    to={x.redirectionLink || "#"}
                    className="px-5 dropdown-header-animation"
                    activeClassName="menu-item-active"
                    onClick={() => handleLinkClick(x.redirectionLink)}
                  >
                    {x.title}
                  </Nav.Link>
                )
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}
