import React, { lazy } from "react";
import { Route } from "react-router-dom";
// import { TopupMyAccountsLoadingDialog } from "./topupMyAccounts-loading-dialog/TopupMyAccountsLoadingDialog";
import { TopupMyAccountsUpdateStatusDialog } from "./topupMyAccounts-update-status-dialog/TopupMyAccountsUpdateStatusDialog";
import { TopupMyAccountsCard } from "./TopupMyAccountsCard";
import { TopupMyAccountsUIProvider } from "./TopupMyAccountsUIContext";
import { TopupMyAccountsLoadingDialog } from "./topupMyAccounts-loading-dialog/TopupMyAccountsLoadingDialog";
import { BestOfferForYouActivation } from "./best-offer-dialog/BestOfferForYouActivation";

export function TopupMyAccountPage({ history }) {
  const topupMyAccountsUIEvents = {
    openUpdateTopupMyAccountsStatusDialog: () => {
      history.push("/my-korek/topup-my-account/updateStatus");
    },
    openActivateBestOfferForYou: (id) => {
      history.push(`/my-korek/topup-my-account/activate-best-offer/${id}`);
    },
    // openValidity: () => {
    //   history.push("/my-korek/topup-my-account/validity");
    // },
  };


  return (
    <TopupMyAccountsUIProvider topupMyAccountsUIEvents={topupMyAccountsUIEvents}>
      <TopupMyAccountsLoadingDialog />
      <Route path="/my-korek/topup-my-account/updateStatus">
        {({ history, match }) => (
          <TopupMyAccountsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/my-korek/topup-my-account");
            }}
          />
        )}
      </Route>
      <Route path="/my-korek/topup-my-account/activate-best-offer/:id">
        {({ history, match }) => (
          <BestOfferForYouActivation
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/my-korek/topup-my-account");
            }}
          />
        )}
      </Route>
      <TopupMyAccountsCard />
    </TopupMyAccountsUIProvider>
  );
}
