import React, { useMemo } from "react";
import { useTopupMyAccountsUIContext } from "./TopupMyAccountsUIContext";
import "../../../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { Divider } from "@material-ui/core";
import { AddValididty, fetchLoadDataValididty } from "../../_redux/topupMyAccounts/topupMyAccountsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Image, Row, Col, Button, Card } from "react-bootstrap";
import "../../../../../_metronic/_assets/sass/pages/my-korek/topup-my-account.scss";
import { FormattedDate, FormattedMessage } from "react-intl";

export function ValidityPage() {
  const dispatch = useDispatch()

  useEffect(() => {  
    dispatch(fetchLoadDataValididty())
  },[]);
  
  const { currentState } = useSelector(
    (state) => ({ currentState: state.topupMyAccounts }),
    shallowEqual
  );
  const { loadDataValididty,listLoading } = currentState;
  return (
    <Card className="p-5">
    <h3><b><FormattedMessage id="TOP_UP.ADD_VALIDITY"></FormattedMessage></b></h3>
    <div className="topup-my-account mx-3 py-5">
      <Row className="gap-2">
        {loadDataValididty && loadDataValididty.map((x, i) => (
          <Col
          style={{border:"1px solid lightgrey"}}
            className={
              i == 0
                ? "box mr-2"
                : i == loadDataValididty.length - 1
                ? "box ml-2"
                : "box mx-2"
            }
          >
            <Image height={56} className="mx-auto" src={x.image}></Image>
            <br />
            <p className="upper">{x.name}</p>{" "}
            <p className="lower">{x.price}</p>
            <Button
            disabled={listLoading}
            onClick={()=>dispatch(AddValididty({
                "idData": x.id,
                "offerName": x.offerName
            }))} className="btn btn-small btn-warning">
              <b><FormattedMessage id="TOP_UP.APPLY_VALIDITY"></FormattedMessage></b>
                {listLoading && (
                  <span className="mx-3 spinner spinner-white"></span>
                )}
            </Button>
          </Col>
        ))}
      </Row>
      </div></Card>
  );
}
