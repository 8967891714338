import { enqueueSnackbar } from "notistack";
import * as requestFromServer from "./activeOffersCrud";
import {activeOffersSlice, callTypes} from "./activeOffersSlice";

const {actions} = activeOffersSlice;

export const fetchMyActiveOffers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyActiveOffers(queryParams)
    .then(response => {
      const { listActiveOffers, listOffersHistory } = response.data;
      dispatch(actions.myActiveOffersFetched({ listActiveOffers, listOffersHistory }));
    })
    .catch(error => {
      error.clientMessage = "Can't find activeOffers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const stopAutoRenewal = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .stopAutoRenewal(queryParams)
    .then(response => {
      const { statusCode } = response.data;
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
      dispatch(actions.autoRenewalStopped({ statusCode }));
    })
    .catch(error => {
      error.clientMessage = "Can't find activeOffers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const activeOffer = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .activeOffer(queryParams)
    .then(() => {
      dispatch(actions.offerActivated());
    })
    .catch(error => {
      error.clientMessage = "Can't update activeVasOffers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};