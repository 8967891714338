// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/myBills/myBillsActions";
import * as uiHelpers from "../MyBillsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useMyBillsUIContext } from "../MyBillsUIContext";
import { useIntl } from "react-intl";

export function MyBillsTable() {
  // MyBills UI Context
  const myBillsUIContext = useMyBillsUIContext();
  const myBillsUIProps = useMemo(() => {
    return {
      ids: myBillsUIContext.ids,
      setIds: myBillsUIContext.setIds,
      queryParams: myBillsUIContext.queryParams,
      setQueryParams: myBillsUIContext.setQueryParams,
      openEditMyBillPage: myBillsUIContext.openEditMyBillPage,
      openDeleteMyBillDialog: myBillsUIContext.openDeleteMyBillDialog,
      openPayBill: myBillsUIContext.openPayBill,
      openDetails: myBillsUIContext.openDetails,
    };
  }, [myBillsUIContext]);

  // Getting curret state of myBills list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.myBills }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // MyBills Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    // myBillsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchMyBills());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBillsUIProps.queryParams, dispatch]);
  // Table columns
  const intl = useIntl()
  const columns = [
    {
      dataField: "title",
      text: intl.formatMessage({id:"BILLS.TITLE"}),
      // sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "amount",
      text: intl.formatMessage({id:"BILLS.AMOUNT"}),
      // sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "date",
      text: intl.formatMessage({id:"BILLS.DATE"}),
      // sort: true,
      sortCaret: sortCaret,
      // formatter: columnFormatters.PriceColumnFormatter,
    },
    {
      dataField: "status",
      text: intl.formatMessage({id:"BILLS.STATUS"}),
      // sort: true,
      // sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
    },
    // {
    //   dataField: "condition",
    //   text: "Condition",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.ConditionColumnFormatter,
    // },
    {
      dataField: "action",
      text: intl.formatMessage({id:"BILLS.ACTIONS"}),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditMyBillPage: myBillsUIProps.openEditMyBillPage,
        openDeleteMyBillDialog: myBillsUIProps.openDeleteMyBillDialog,
        openPayBill: myBillsUIProps.openPayBill,
        openDetails: myBillsUIProps.openDetails,
      },
      classes: "text-center pr-0",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: myBillsUIProps.queryParams.pageSize,
    page: myBillsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  myBillsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: myBillsUIProps.ids,
                //   setIds: myBillsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
