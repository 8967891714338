/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const [showLogoutConf, setLogoutConf] = useState(false)
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              <FormattedMessage id="HI" />
            </span>{" "}
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
              {user.userProfile.firstName} {user.userProfile.lastName}
            </span>
            <span className="symbol symbol-35 symbol-light-warning">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {user.userProfile.firstName[0]}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (
              <>
                <div className="d-flex align-items-center p-8 rounded-top">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {user.userProfile.firstName} {user.userProfile.lastName}
                  </div>
                </div>
                <div className="separator separator-solid"></div>
              </>
            )}

            {!layoutProps.light && (
              <div
                className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/misc/bg-1.jpg"
                  )})`,
                }}
              >
                <div className="symbol bg-white-o-15 mr-3">
                  <span className="symbol-label text-warning font-weight-bold font-size-h4">
                    {user.userProfile.firstName[0]}
                  </span>
                  {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
                </div>
                <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.userProfile.firstName} {user.userProfile.lastName}
                </div>
                {/* <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span> */}
              </div>
            )}
          </>

          <div className="navi navi-spacer-x-0 pt-5">
            {/* <Link to="/my-korek" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Korek
                </div>
              </div>
            </div>
          </Link> */}

            {/* <div className="navi-separator mt-3"></div> */}

            <div className="navi-footer  px-8 py-5">
              <NavLink
                to="/my-korek"
                className="btn btn-light-warning font-weight-bold">

                <FormattedMessage id="AUTH.MyKorek" />
              </NavLink>
              <Button
                onClick={() => setLogoutConf(true)}
                className="btn btn-light-danger font-weight-bold"
              >
                <FormattedMessage id="AUTH.SIGNOUT" />
              </Button>
              {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <Modal
        backdrop="static" show={showLogoutConf} onHide={() => setLogoutConf(false)}>
        {/* <Modal.Header
          className="justify-content-end"
          style={{ borderBottom: "0px" }}
          closeButton
        ></Modal.Header> */}
        <Modal.Body>
          <div className="text-center modalHeader">
            <h3 className="font-size-h3">
              <b>
                {/* Are you sure do you want to sign out? */}
                <FormattedMessage id="AUTH.SIGNOUT_CONF" />
              </b>
            </h3>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button
          onClick={()=>setLogoutConf(false)}
            className="btn btn-light font-weight-bold"
          >
                <FormattedMessage id="RECHARGE.CANCEL" />
          </Button>
          <></>
          <Link
            to="/logout"
            className="btn btn-light-danger font-weight-bold"
          >
                <FormattedMessage id="AUTH.SIGNOUT" />
          </Link>
        </Modal.Footer>
      </Modal></>
  );
}
