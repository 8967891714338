/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { Offcanvas, Row, NavDropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export function UserNotificationsDropdown() {
  const [show, setShow] = useState(false);
  const uiService = useHtmlClassService();
  const navbarItems = useSelector((state) => state.profileSlice.navbarItems, shallowEqual);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      {/* {layoutProps.offcanvas && ( */}
      <div className="topbar-item">
        <div
          className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-light"
          id="kt_quick_notifications_toggle"
          onClick={handleShow}
        >
          <span className="svg-icon svg-icon-xl svg-icon-light">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Other1.svg")} />
          </span>
          {/* <span className="pulse-ring"></span> */}
        </div>
      </div>
      {/* )} */}

      <Offcanvas show={show} backdrop={false} onHide={handleClose} placement="end" className="custom-offcanvas">
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title><FormattedMessage id="FOOTER.NOTIFICATIONS" /></Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="mx-auto px-3" style={{justifyContent:"space-between"}}>
            <NavLink className="menu-link nav-link" to="/about-us" onClick={handleClose}>
              <FormattedMessage id="FOOTER.ABOUT_US" />
            </NavLink>
            <a target="_blank" className="menu-link nav-link" href="https://careers.korektel.com/" onClick={handleClose}>
              <FormattedMessage id="FOOTER.CAREERS" />
            </a>
            <NavLink className="menu-link nav-link" to="/our-location" onClick={handleClose}>
              <FormattedMessage id="FOOTER.OUR_LOCATION" />
            </NavLink>
            <NavLink className="menu-link nav-link" to="/contact" onClick={handleClose}>
              <FormattedMessage id="FOOTER.CONTACT" />
            </NavLink>
          </Row>

          {navbarItems && navbarItems.filter(x => x.id !== "5").map((x) => (
            x.sections.length > 0 ? (
              <NavDropdown key={x.id} title={x.title}
                className="mx-4 text-left mobile-nav">
                <NavLink
                  className="dropdown-header-animation dropdown-item text-dark"
                  to={x.redirectionLink}
                  activeClassName="menu-item-active"
                  onClick={handleClose} // Hide dropdown on click
                >
                  <b><FormattedMessage id="ACTIVE_OFFERS.ALL" /></b>
                </NavLink>
                {x.sections.map((y) => (
                  <div key={y.id}>
                    <NavLink
                      className="dropdown-header-animation text-left nav-link text-dark"
                      to={y.redirectionLink}
                      activeClassName="menu-item-active"
                      onClick={() => {
                        // if (y.redirectionLink === "/korek-services/4" && !user) {
                        //   enqueueSnackbar("Login Needed", { variant: "warning" });
                        // }
                        handleClose(); // Hide dropdown on click
                      }}
                    >
                      <b>{y.title}</b>
                    </NavLink>
                    {y.subsections && y.subsections.map((z) => (
                      <NavLink
                        key={z.id}
                        className="dropdown-header-animation text-left nav-link text-dark"
                        to={z.redirectionLink}
                        activeClassName="menu-item-active"
                        onClick={handleClose} // Hide dropdown on click
                      >
                        {z.title}
                      </NavLink>
                    ))}
                  </div>
                ))}
              </NavDropdown>
            ) : (
              <NavLink
                key={x.id}
                className="mx-4 dropdown-header-animation text-left nav-link"
                style={{ color: "#414042", fontWeight: "600" }}
                to={x.redirectionLink}
                activeClassName="menu-item-active"
                onClick={handleClose} // Hide dropdown on click
              >
                {x.title}
              </NavLink>
            )
          ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
