import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TopupMyAccountStatusCssClasses } from "../TopupMyAccountsUIHelpers";
import * as actions from "../../../_redux/topupMyAccounts/topupMyAccountsActions";
import { useTopupMyAccountsUIContext } from "../TopupMyAccountsUIContext";
import { GUID } from "../../../../../../_utils/Axios";
import { FormattedMessage } from "react-intl";

export function BestOfferForYouActivation({ id, show, onHide }) {
  // TopupMyAccounts UI Context
  const topupMyAccountsUIContext = useTopupMyAccountsUIContext();
  const topupMyAccountsUIProps = useMemo(() => {
    return {
      ids: topupMyAccountsUIContext.ids,
      setIds: topupMyAccountsUIContext.setIds,
      queryParams: topupMyAccountsUIContext.queryParams,
    };
  }, [topupMyAccountsUIContext]);

  // TopupMyAccounts Redux state
  const { topupMyAccounts, isLoading } = useSelector(
    (state) => ({
      isLoading: state.topupMyAccounts.actionsLoading,
    }),
    shallowEqual
  );

  // if there weren't selected topupMyAccounts we should close modal
  useEffect(() => {
    if (id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.topupMyAccounts }),
    shallowEqual
  );
  const { accountItems,bestOffersForYou } = currentState;
  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    let params = {
      idData: id,
      idOfferType: 2,
      idRequest: GUID(),
      offerName:bestOffersForYou && bestOffersForYou.list.filter(x=>x.idData == id)[0].name
    };
    // server request for updateing topupMyAccount by ids
    dispatch(actions.activateOffer(params)).then(
      () => {
        // refresh list after deletion
        dispatch(actions.fetchMyTopupMyAccounts()).then(
          () => {
            // clear selections list
            // topupMyAccountsUIProps.setIds([]);
            // closing delete modal
            onHide();
          }
        );
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
         <FormattedMessage id="TOP_UP.BEST_OF_FOR_YOU.ACT"></FormattedMessage>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
        <FormattedMessage id="TOP_UP.BEST_OF_FOR_YOU.CONF"></FormattedMessage>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="RECHARGE.CANCEL"></FormattedMessage>
          </button>
          <> </>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-warning btn-elevate"
          >
            <b><FormattedMessage id="TOP_UP.ACTIVATE_OFFER"></FormattedMessage></b>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
