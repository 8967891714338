/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState, useCallback } from "react";
import objectPath from "object-path";
import { Link, useLocation } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Col, Dropdown, Nav, Navbar, NavDropdown, Row, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../_helpers";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { GetLogo, GetLogoBusiness } from "../../../_helpers/GetLogo";
import Login from "../extras/Login";

// Helper component for dropdown items
const NavDropdownItem = React.memo(({ item }) => (
  <div style={{ border: "none", backgroundColor: 'transparent' }} className="card mb-0">
    <NavLink
      to={item.redirectionLink || '#'}
      className="dropdown-item"
      activeClassName="menu-item-active"
    >
      {item.title}
    </NavLink>
  </div>
));

export function SubHeaderBusinessScroll() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  let history = useHistory();


  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const businessNavbarItems = useSelector(
    (state) => state.profileSlice.businessNavbarItems,
    shallowEqual
  );
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  // useLayoutEffect(() => {
  //   const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
  //   const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
  //   const breadcrumbs =
  //     aside && aside.breadcrumbs.length > 0
  //       ? aside.breadcrumbs
  //       : header.breadcrumbs;
  //   subheader.setBreadcrumbs(breadcrumbs);
  //   subheader.setTitle(
  //     aside && aside.title && aside.title.length > 0
  //       ? aside.title
  //       : header.title
  //   );
  //   // eslint-disable-next-line
  // }, [location.pathname]);
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);
  const [show, setShow] = useState("");
  const showDropdown = (e) => {
    setShow(e);
  };
  const hideDropdown = (e) => {
    setShow("");
  };
  const [showServices, setShowServices] = useState(false);
  const routeChange = (path) => {
    // let path = `/korek-offers/all-offers`;
    history.push(path);
  };
  const getHeaderLogo = () => {
    let result = "logo-light.png";
    if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
      result = "logo-dark.png";
    }
    return toAbsoluteUrl(`/media/logos/${result}`);
  };

  const handleDropdownToggle = useCallback((id) => {
    setShow(prev => (prev === id ? "" : id));
  }, []);


  return (
    <div
      id="kt_subheader"
      style={{ width: "100%", right: "auto" }}
      className={`subheader subheader-Scroll py-2 py-lg-4 subheader-business  centerFixed ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} maxWidthCont d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        <Navbar className="navbar-dark" expand="lg">
          <Navbar.Brand href="#home">

            <NavLink className="menu-link logo-subheader-mobile" to="/">
              <img alt="logo" src={GetLogoBusiness("dark")} />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {businessNavbarItems &&
                businessNavbarItems.map((x, i) =>
                   (
                    <NavDropdown
                      key={x.id}
                      title={x.title}
                      className="pr-5 navbar-scroll dropdown-header-animation"
                      id={`offcanvasNavbarDropdown-expand-${"xl"}`} 
                      onMouseEnter={() => handleDropdownToggle(x.id)}
                      onMouseLeave={() => handleDropdownToggle(x.id)}
                      // onClick={() => routeChange(x.redirectionLink)}
                    style={{paddingTop:"1.3rem"}}
                      show={show == x.id}
                    >
                    <div style={{ columnCount: 4 }} className="card-columns padding-sub py-0 dropdown-header-animation maxWidthCont">
                      {x.subsections.map((y, index) => (
                        <NavDropdownItem key={index} item={y} />
                      ))}
                    </div>
                    </NavDropdown>
                  ) 
                )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="scroll-login  logo-subheader-mobile">
          {layoutProps.viewUserDisplay && <Login />}</div>
      </div>
    </div>
  );
}
