import * as requestFromServer from "./activeVasOffersCrud";
import {activeVasOffersSlice, callTypes} from "./activeVasOffersSlice";

const {actions} = activeVasOffersSlice;

export const fetchMyActiveVasOffers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyActiveVasOffers(queryParams)
    .then(response => {
      const { activeVasOffersList } = response.data;
      dispatch(actions.myActiveVasOffersFetched({ activeVasOffersList }));
    })
    .catch(error => {
      error.clientMessage = "Can't find activeVasOffers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateActiveVasOffersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForActiveVasOffers(ids, status)
    .then(() => {
      dispatch(actions.activeVasOffersStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update activeVasOffers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const activeVasOffer = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .activeVasOffer(queryParams)
    .then(() => {
      dispatch(actions.vasOfferActivated());
    })
    .catch(error => {
      error.clientMessage = "Can't update activeVasOffers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
