import React, { useMemo } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Card as CardC } from "../../../../../_metronic/_partials/controls";
import { MyBillsFilter } from "./myBills-filter/MyBillsFilter";
import { MyBillsTable } from "./myBills-table/MyBillsTable";
import { useMyBillsUIContext } from "./MyBillsUIContext";
import "../../../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { Button, Row, Col, Image, ProgressBar } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { useIntl } from "react-intl";

export function MyBillsCard() {
  const myBillsUIContext = useMyBillsUIContext();
  const myBillsUIProps = useMemo(() => {
    return {
      ids: myBillsUIContext.ids,
      queryParams: myBillsUIContext.queryParams,
      setQueryParams: myBillsUIContext.setQueryParams,
      openUpdateMyBillsStatusDialog:
        myBillsUIContext.openUpdateMyBillsStatusDialog,
    };
  }, [myBillsUIContext]);

  const intl = useIntl()
  return (
    <div className="my-active-offers">
      <CardC>
        <CardHeader title={intl.formatMessage({id:"RECHARGE.PAYMENT_HISTORY"})}>
          <CardHeaderToolbar>
            <MyBillsFilter />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className="p-0">
          {/* {myBillsUIProps.ids.length > 0 && (
          <>
            <MyBillsGrouping />
          </>
        )} */}
          <MyBillsTable />
        </CardBody>
      </CardC>
    </div>
  );
}
