import React from "react";
import { Box } from "@material-ui/core";
import { useFormikWizard } from "formik-wizard";
import { CheckCircle } from "@material-ui/icons";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const checkProperties = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== "") return false;
  }
  return true;
};

const Nav = ({ currentStep, wizard }) => {
  const context = useFormikWizard();


  return (
    <Box className="d-flex">
      <div id="top"></div>
      <Button
        className="nav-wizard-item"
        onClick={() => {
          wizard.push("basic");
        }}
        // color="primary"
        variant={currentStep === "basic" ? "contained" : "text"}
      >
        {currentStep === "basic" ||
          currentStep === "address" ||
          currentStep === "company" ? (
          <CheckCircle fontSize="100px" color="primary" />
        ) : (
          <div className="empty-circle"></div>
        )}
        <span className="mx-5"><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.SEARCH_NUMBER" /></span>
      </Button>
      <hr className="new" />
      <Button
        className="nav-wizard-item"
        onClick={() => {
          if (checkProperties(context.values.basic)) {
            wizard.push("basic");
          } else {
            wizard.push("address");
          }
        }}
        // color="primary"
        variant={currentStep === "address" ? "contained" : "text"}
      >
        {currentStep === "address" || currentStep === "company" ? (
          <CheckCircle fontSize="100px" color="primary" />
        ) : (
          <div className="empty-circle"></div>
        )}
        <span className="mx-5"><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.CUSTU_INFO" />
        </span></Button>
      <hr className="new" />
      <Button
        className="nav-wizard-item"
        onClick={() => {
          if (checkProperties(context.values.basic)) {
            wizard.push("basic");
          } else if (checkProperties(context.values.address)) {
            wizard.push("address");
          } else {
            wizard.push("company");
          }
        }}
        // color="primary"
        variant={currentStep === "company" ? "contained" : "text"}
      >
        {currentStep === "company" ? (
          <CheckCircle fontSize="100px" color="primary" />
        ) : (
          <div className="empty-circle"></div>
        )}
        <span className="mx-5"><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.SUMMARY" />
        </span></Button>
      {/* <Button
        onClick={() => {
          if (checkProperties(context.values.basic)) {
            wizard.push("basic");
          } else if (checkProperties(context.values.address)) {
            wizard.push("address");
          } else if (checkProperties(context.values.company)) {
            wizard.push("company");
          } else {
            wizard.push("account");
          }
        }}
        color="primary"
        variant={currentStep === "account" ? "contained" : "text"}
      >
        Step 4
      </Button> */}
    </Box>
  );
};

export default Nav;
