import axios from "axios";
import { sendRequestDotNet } from "../../../../../_utils/Axios";

export const CUSTOMERS_URL = "api/rechargeLines";
export const OFFER_URL = "Offer";
export const ACCOUNT_URL = "Account";
export const CONTROL_URL = "Control";


export function findMyRechargeLines() {
  return sendRequestDotNet(`${OFFER_URL}/MyRechargeLines`, {});
}
export function findHistoryPrepaidRecharge(queryParams) {
  return sendRequestDotNet(`${ACCOUNT_URL}/HistoryPrepaidRecharge`, queryParams);
}
export function rechargeScratchCardd(queryParams) {
  return sendRequestDotNet(`${ACCOUNT_URL}/RechargeScratchCard`, queryParams);
}
export function rechargePrePaid(queryParams) {
  return sendRequestDotNet(`${ACCOUNT_URL}/GetPostpaidWalletUrl`, queryParams);
}
export function findLoansDetails(queryParams) {
  return sendRequestDotNet(`${CONTROL_URL}/GetDetails`, queryParams);
}
export function applyLoan(queryParams) {
  return sendRequestDotNet(`${CONTROL_URL}/ApplyLoan`, queryParams);
}
export function autoCreditLoan(queryParams) {
  return sendRequestDotNet(`${CONTROL_URL}/SwitchAutoLoan`, queryParams);
}

// UPDATE Status
export function updateStatusForRechargeLines(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForRechargeLines`, {
    ids,
    status
  });
}
