/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import * as auth from "../Auth";
import { connectedDevices } from "./_redux/Profile/profileActions";
import moment from "moment";
import { FormattedMessage } from "react-intl";

function AccountInformation(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  
  const { currentState } = useSelector(
    (state) => ({ currentState: state.profileSlice }),
    shallowEqual
  );
  const { list } = currentState;

  useEffect(() => {
    dispatch(connectedDevices())
  }, []);


  return (
    <div className="card card-custom">
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            <FormattedMessage id="AUTH.ACCOUNT_ACT"></FormattedMessage>
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            <FormattedMessage id="AUTH.CURR_LOGIN"></FormattedMessage>
          </span>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
{
  list && list.map((x,i)=>(
    
    <div key={x.id} className="card card-custom my-2">
        <div className="card-body">
          <h5 className="text-primary"><b>{x.deviceModel}</b></h5>
          <h5 style={{color:"grey"}}>{moment(x.lastLoginDate).format("MMMM Do YYYY, h:mm:ss a")}</h5>
          </div></div>
  ))
}
        </div>
      </div>
      {/* end::Form */}
    </div>
  );
}
export default connect(null, auth.actions)(AccountInformation);
