import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MyBillStatusCssClasses } from "../MyBillsUIHelpers";
import * as actions from "../../../_redux/myBills/myBillsActions";
import { useMyBillsUIContext } from "../MyBillsUIContext";
import { FormattedMessage } from "react-intl";

export function PayBillDialog({ id, show, onHide }) {
  // MyBills UI Context
  const myBillsUIContext = useMyBillsUIContext();
  const myBillsUIProps = useMemo(() => {
    return {
      ids: myBillsUIContext.ids,
      setIds: myBillsUIContext.setIds,
      queryParams: myBillsUIContext.queryParams,
    };
  }, [myBillsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.myBills }),
    shallowEqual
  );
  const { totalCount, entities, isLoading, url } = currentState;

  // if there weren't selected myBills we should close modal
  useEffect(() => {
    if (myBillsUIProps.ids || myBillsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBillsUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    let params = {
      // "receiverMsisdn": "",
      amount:
        id && entities && entities.filter((x) => id == x.id)[0].amountInteger,
    };
    // server request for updateing myBill by ids
    dispatch(actions.payMyBill(params)).then((redirect) => {
      redirect && window.open(redirect, "_blank");
      dispatch(actions.fetchMyBills()).then(() => {
        onHide();
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Status has been updated for selected myBills
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="overlay overlay-block cursor-default">
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
        <div className="text-center">
          <h3>
            <b><FormattedMessage id="BILLS.PAY_BILLS"></FormattedMessage></b>
          </h3>
          <p><FormattedMessage id="BILLS.MONTHLY_BILLS"></FormattedMessage></p>
          <div
            className="mx-auto"
            style={{
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#E8E8E8",
              width: "75%",
              fontWeight: "700",
              fontSize: "1.5rem",
            }}
          >
            {id && entities && entities.filter((x) => id == x.id)[0].amount}
          </div>
          <div
            className="mx-auto"
            style={{ padding: "10px", fontSize: "1rem", fontWeight: "500" }}
          ><FormattedMessage id="BILLS.DUE_DATE"></FormattedMessage>
            {" "}
            {id && entities && entities.filter((x) => id == x.id)[0].date}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="form flex-center">
        <div className="form-group">
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-warning btn-elevate"
            disabled={isLoading}
          >
            <b><FormattedMessage id="BILLS.PAYNASSWALLET"></FormattedMessage></b>
            {isLoading && <span className="mx-3 spinner spinner-white"></span>}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
