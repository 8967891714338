import React, { useMemo } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Card as CardC } from "../../../../../_metronic/_partials/controls";
import { ActiveVasOffersFilter } from "./activeVasOffers-filter/ActiveVasOffersFilter";
import { ActiveVasOffersTable } from "./activeVasOffers-table/ActiveVasOffersTable";
import { useActiveVasOffersUIContext } from "./ActiveVasOffersUIContext";
import "../../../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { Button, Row, Col, Image, ProgressBar } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { fetchMyActiveVasOffers } from "../../_redux/activeVasOffers/activeVasOffersActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

export function ActiveVasOffersCard() {
  const activeVasOffersUIContext = useActiveVasOffersUIContext();
  const dispatch = useDispatch()
  
  const activeVasOffersUIProps = useMemo(() => {
    return {
      ids: activeVasOffersUIContext.ids,
      queryParams: activeVasOffersUIContext.queryParams,
      setQueryParams: activeVasOffersUIContext.setQueryParams,
      openUpdateActiveVasOffersStatusDialog:
        activeVasOffersUIContext.openUpdateActiveVasOffersStatusDialog,
      openRateOfferDialog:
        activeVasOffersUIContext.openRateOfferDialog,
    };
  }, [activeVasOffersUIContext]);

  
  useEffect(() => {  
    dispatch(fetchMyActiveVasOffers())
  },[]);
  
  const { currentState } = useSelector(
    (state) => ({ currentState: state.activeVasOffers }),
    shallowEqual
  );
  const { activeVasOffersList } = currentState;
  return (
    <div className="my-active-offers">
        <div className="card card-custom card-stretch mb-5">
          <div className="card-body">
          <Row className="head-title"><FormattedMessage id="ACTIVE_VAS_OFFERS.ACTIVE_OFFERS"></FormattedMessage>
            </Row>
            {/* {
              activeVasOffersList && activeVasOffersList.map((activeOf)=>(

              ))
            } */}
            {activeVasOffersList && (activeVasOffersList.length>0? activeVasOffersList.map(x=>(
                          <Row key={x.id} className="my-5">
              <Col xl={2}>
                <div className="symbol symbol-150 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url(${x.imageUrl})`,
                    }}
                  ></div>
                </div>
              </Col>
              <Col xl={10}>
                  <Row className="mx-auto pt-5" style={{ width: "100%" }}>
                    <Col className="pb-4" xl={12}>
                <div className="font-weight-bolder offer-name">
                  {x.name}
                   {/* - {x.price} */}
            {/* <Button onClick={()=> activeVasOffersUIProps.openRateOfferDialog()} style={{float:"right"}} className="btn btn-outline-primary"><b>Rate Offer</b></Button> */}
                </div></Col>
                  </Row>
              </Col>
            </Row>
            )):<p className="p-3"><FormattedMessage id="NO.ACTIVE.OFFER.AVAI"></FormattedMessage></p>)}

        </div>
      </div>
      {/* <CardC>
        <CardHeader title="Bundles History">
          <CardHeaderToolbar>
            <ActiveVasOffersFilter />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className="p-0">
          {activeVasOffersUIProps.ids.length > 0 && (
          <>
            <ActiveVasOffersGrouping />
          </>
        )}
          <ActiveVasOffersTable />
        </CardBody>
      </CardC> */}
    </div>
  );
}
