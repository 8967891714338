import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Divider } from "@material-ui/core";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { checkIsActive, checkIsActiveLink, toAbsoluteUrl } from "../../../_helpers";
import { Col, Row } from "react-bootstrap";
import { KorekApp } from "../../../../app/modules/Web/pages/Home/KorekApp";
import { GetLogo, GetLogoBusiness } from "../../../_helpers/GetLogo";
import { shallowEqual, useSelector } from "react-redux";
import { KorekAppBusiness } from "../../../../app/modules/Web/pages/Home/KorekAppBusiness";
import { Check } from "@material-ui/icons";
import { useLocation } from "react-router";
import { FormattedMessage } from "react-intl";

export function FooterBusiness() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const location = useLocation()

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  const getHeaderLogo = () => {
    let result = "logo_white.png";
    if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
      result = "logo_large_1_.png";
    }
    return toAbsoluteUrl(`/media/logos/${result}`);
  };

  const socialMedia = useSelector(
    (state) => state.profileSlice.socialMedia,
    shallowEqual
  );
  return (
    <>
      <KorekAppBusiness />
      <div style={{ width: "100%", flexDirection: "column" }}
        className={`footer footer-business py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
        id="kt_footer"
      >
        <div
          className={`${layoutProps.footerContainerClasses} maxWidthCont d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          <Row className="maxWidthCont" style={{ width: "100%" }}>
            <Col xl={4} sm={12} xs={12}>

              <NavLink className="menu-link" to="/">
                <img alt="logo" src={GetLogoBusiness("dark")} />
              </NavLink><br />
              {socialMedia && <div className="d-flex flex-row my-5">
                <a target="_blank" rel="noopener noreferrer" className="menu-link mx-2" href={socialMedia.fb}>
                  <img alt="logo" src={toAbsoluteUrl("/media/logos/facbook_1_.png")} />
                </a>
                <a target="_blank" rel="noopener noreferrer" className="menu-link mx-2" href={socialMedia.twitter}>
                  <img alt="logo" src={toAbsoluteUrl("/media/logos/twitter_1_.png")} />
                </a>
                <a target="_blank" rel="noopener noreferrer" className="menu-link mx-2" href={socialMedia.linkedIn}>
                  <img alt="logo" src={toAbsoluteUrl("/media/logos/linkedin_1_.png")} />
                </a>
                <a target="_blank" rel="noopener noreferrer" className="menu-link mx-2" href={socialMedia.youtube}>
                  <img alt="logo" src={toAbsoluteUrl("/media/logos/youtube_1_.png")} />
                </a>
                <a target="_blank" rel="noopener noreferrer" className="menu-link mx-2" href={socialMedia.insta}>
                  <img alt="logo" src={toAbsoluteUrl("/media/logos/instagram_1_.png")} />
                </a>
              </div>}


            </Col>
            <Col xl={4} sm={7} xs={12}>
              <Row className="text-light">
                <Col xl={6} sm={6} xs={6}><h4>
                  <FormattedMessage id="FOOTER.HELP"></FormattedMessage></h4>
                  <ul className={`menu-nav no-bullets pt-2  ${layoutProps.ulClasses}`}>
                    <li className={`menu-item menu-item-rel`}>
                      <NavLink className="menu-link" to="/korek-offers/all-offers">
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.KOREK_OFFERS"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel`}>
                      <NavLink className="menu-link" to="/korek-services/all-services">
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.KOREK_SERVICES"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel`}>
                      <NavLink className="menu-link" to="/entertainment/all-entertainment">
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.ENT"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel`}>
                      <NavLink className="menu-link" to="/blue-program">
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.BLUE_PROG"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                    </li>
                    {/* <li className={`menu-item menu-item-rel`}>
                <NavLink className="menu-link" to="/">
                    <span className="menu-text">e-commerce</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel`}>
                <NavLink className="menu-link" to="/">
                    <span className="menu-text">FAQ</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
                  </ul>
                </Col>
                <Col xl={6} sm={6} xs={6}><h4>
                  <FormattedMessage id="FOOTER.ABOUT_KOREK"></FormattedMessage></h4>
                  <ul className={`menu-nav no-bullets pt-2 ${layoutProps.ulClasses}`}>
                    <li className={`menu-item menu-item-rel`}>
                      <NavLink className="menu-link" to="/about-us">
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.ABOUT_US"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel`}>
                      <NavLink className="menu-link" to="/contact">
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.CONTACT"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel`}>
                      <NavLink className="menu-link" to="/our-location">
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.LOCATE_US"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                      </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel`}>
                      <a target="_blank" className="menu-link" href="https://careers.korektel.com/">
                        {/* <NavLink className="menu-link" to="/"> */}
                        <span className="menu-text">
                          <FormattedMessage id="FOOTER.CAREERS"></FormattedMessage></span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                        {/* </NavLink> */}
                      </a>
                    </li>
                  </ul></Col>
              </Row>
            </Col>
            <Col xl={4} sm={5} xs={12} className="mt-5">

              <div className="footer-per-bus d-flex flex-row flex-end p-2">
                { }
                <NavLink className="menu-link text-light" to="">
                  <p className="p-2 mb-0">
                    {" "}
                    {!checkIsActiveLink(location, "/business") && <Check></Check>}
                    <FormattedMessage id="FOOTER.PERSONAL"></FormattedMessage>
                  </p>
                </NavLink>
                <NavLink className="menu-link text-light" to="/business/home">
                  <p className="p-2 mb-0">
                    {checkIsActive(location, "/business") && (
                      <Check></Check>
                    )}

                    <FormattedMessage id="FOOTER.Business"></FormattedMessage>
                  </p>
                </NavLink>
              </div>
            </Col>
          </Row>
        </div>
        <Divider className="my-5" />
        <div
          className={`${layoutProps.footerContainerClasses} maxWidthCont d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          <div className="text-light order-2 order-md-1">&copy;{" "}
            <FormattedMessage id="FOOTER.KOREK_TEL"></FormattedMessage>{" "}
            {today.toString()}.
            <FormattedMessage id="FOOTER.ALL_RIGHT"></FormattedMessage>
          </div>
          <div className="nav nav-light order-1 order-md-2">
            <a
              rel="noopener noreferrer"
              className="nav-link pr-3 pl-0 text-light"
            >
              <FormattedMessage id="FOOTER.PRIVACY"></FormattedMessage>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
