/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useCallback, useState } from "react";
import objectPath from "object-path";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import { checkIsActive, toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { useSubheader } from "../../_core/MetronicSubheader";
import { shallowEqual, useSelector } from "react-redux";
import { GetLogo } from "../../../_helpers/GetLogo";
import Login from "../extras/Login";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { enqueueSnackbar } from "notistack";

export function SubHeaderScroll() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const navbarItems = useSelector(
    (state) => state.profileSlice.navbarItems,
    shallowEqual
  );

  const layoutProps = useMemo(() => ({
    config: uiService.config,
    headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
    subheaderCssClasses: uiService.getClasses("subheader", true),
    subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
  }), [uiService]);

  const [show, setShow] = useState("");

  const handleDropdownToggle = useCallback((id) => {
    setShow(prev => (prev === id ? "" : id));
  }, []);

  const routeChange = (path) => {
    history.push(path);
  };

  const NavDropdownItem = ({ item }) => (
    <div style={{ border: "none", backgroundColor: 'transparent' }} className="card mb-0">
      <NavLink
        to={item.redirectionLink}
        className="dropdown-item"
        activeClassName="menu-item-active"
        onClick={() => {
          setShow("")
          
          // item.redirectionLink == "/korek-services/4" && !user &&
          //   enqueueSnackbar("Login Needed", {
          //     variant: "warning",
          //   });
          }
        }
      >
        {item.image && <Image className="icon-navigation" width={24} src={item.image} />}
        {item.title}
      </NavLink>
      {item.subsections && item.subsections.map((subItem, index) => (
        <NavLink
          key={index}
          to={subItem.redirectionLink}
          className="dropdown-item"
          activeClassName="menu-item-active"
          onClick={() => setShow("")}
        >
          {subItem.title}
        </NavLink>
      ))}
    </div>
  );

  return (
    <div
      id="kt_subheader"
      style={{ width: "100%", right: "auto" }}
      className={`subheader subheader-Scroll py-2 py-lg-4 centerFixed ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap mobile-subheader maxWidthCont`}
      >
        <Navbar className="navbar-dark" expand="lg">
          <Navbar.Brand>
            <NavLink className="menu-link logo-subheader-mobile" to="/">
              <img alt="logo" width={240} src={GetLogo("white")} />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto menu">
              {navbarItems && navbarItems.filter(x => x.id !== "5").map((x) =>
                x.sections.length > 0 ? (
                  <NavDropdown
                    key={x.id}
                    title={
                      <div className="d-flex align-items-center">
                      <NavLink
                        to={x.redirectionLink}
                        className="nav-link"
                        onClick={() => routeChange(x.redirectionLink)}
                      >
                        {x.title}
                      </NavLink>
                      <ExpandMoreOutlined/>
                      </div>
                    }
                    className="pr-5 navbar-scroll dropdown-header-animation maxWidthCont nav-main"
                    id={`offcanvasNavbarDropdown-expand-xl`}
                    onMouseEnter={() => handleDropdownToggle(x.id)}
                    onMouseLeave={() => handleDropdownToggle(x.id)}
                    show={show === x.id}
                  >
                    <div style={{ columnCount: 4 }} className="card-columns padding-sub py-0 dropdown-header-animation maxWidthCont">
                      {x.sections.map((y, i) => (
                        <NavDropdownItem key={i} item={y} />
                      ))}
                    </div>
                  </NavDropdown>
                ) : (
                  <NavLink
                    key={x.id}
                    to={x.redirectionLink}
                    style={{ alignContent: "center" }}
                    className="px-5 dropdown-header-animation nav-link"
                    activeClassName="menu-item-active"
                  >
                    {x.title}
                  </NavLink>
                )
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="scroll-login logo-subheader-mobile mr-0 ml-auto">
          {<Login />}
        </div>
        <LanguageSelectorDropdown />
      </div>
    </div>
  );
}
