import React, { Fragment, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FastField, Field, Formik, useFormikContext } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { sendRequestDotNet } from "../../../../../../../_utils/Axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Radio } from "@material-ui/core";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import CustomField from "../CustomField";
import { useFormikWizard } from "formik-wizard";

export function BasicInfo({ }) {
  // const applyFilter = (values) => {
  // };

  const formRef = useRef();
  const intl = useIntl();
  const { prefixes, positions, priceRanges, governorates, minMaxPrice } = useSelector(
    (state) => state.profileSlice,
    shallowEqual
  );
  // console.log(formRef)

  const { values, submitForm } = useFormikContext();

  const SearchSchema = Yup.object().shape({
    idPosition: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    // upper: Yup.number().when([], {
    //   is: (pattern) => !pattern,
    //   then: Yup.number().required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ).positive()
    //     .integer()
    //     .min(minMaxPrice && minMaxPrice.minPrice, `Min is ${minMaxPrice && minMaxPrice.minPrice}`)
    //     .moreThan(Yup.ref("lower"), `Must Be More Than From`)
    //     .max(minMaxPrice && minMaxPrice.maxPrice, `Max is ${minMaxPrice && minMaxPrice.maxPrice}`),
    //   otherwise: Yup.number().notRequired(),
    // }),
    upper: Yup.number()
    .test(
      'validate-upper',
      `${intl.formatMessage({ id: 'validation.MustMoreBetween' })} ${minMaxPrice?.minPrice} ${intl.formatMessage({ id: 'validation.and' })} ${minMaxPrice?.maxPrice}`,
      function(value) {
        const { pattern, lower } = this.parent;
        if (pattern === undefined) {
          if (value === undefined || value === null) {
            return false;
          }
          if (value <= minMaxPrice?.minPrice || value > minMaxPrice?.maxPrice) {
            return false;
          }
          if (lower !== undefined && lower !== null && value <= lower) {
            return false;
          }
        }
        return true;
      }
    ),
    // .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
  
  lower: Yup.number()
    .test(
      'validate-lower',
      `${intl.formatMessage({ id: 'validation.MustBetween' })} ${minMaxPrice?.minPrice} ${intl.formatMessage({ id: 'validation.and' })} ${minMaxPrice?.maxPrice}`,
      function(value) {
        const { pattern, upper } = this.parent;
        if (pattern === undefined) {
          if (value === undefined || value === null) {
            return false;
          }
          if (value < minMaxPrice?.minPrice || value > minMaxPrice?.maxPrice) {
            return false;
          }
          if (upper !== undefined && upper !== null && value >= upper) {
            return false;
          }
        }
        return true;
      }
    ),
    // .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
  
  pattern: Yup.string()
    .test(
      'validate-pattern',
      intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }),
      function(value) {
        const { upper, lower } = this.parent;
        if ((upper === undefined || upper === null) && (lower === undefined || lower === null)) {
          return value !== undefined && value !== null && value.trim() !== '';
        }
        return true;
      }
    )
    .max(7, intl.formatMessage({ id: 'AUTH.VALIDATION.DID.MAX_LENGTH_FIELD' })),
    prefix: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
  });
  const [list, setList] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [loading, setLoading] = useState(false);
  const context = useFormikWizard();


  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            {intl.formatMessage({
              id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SEARCH_NBR",
            })}
          </Card.Title>
          <Formik
            innerRef={formRef}
            enableReinitialize={false}
            validationSchema={SearchSchema}
            initialValues={{
              idPosition: "",
              pattern: "",
              prefix: "",
              priceRange: {},
            }}
            onSubmit={(values) => {
              setLoading(true);
              // console.log(values);
              let val = values;
              let priceRange = {
                lower: values.lower?parseInt(values.lower):0,
                upper: values.upper?parseInt(values.upper):0,
                // lower: parseInt(values.priceRange.split("-")[0]),
                // upper: parseInt(values.priceRange.split("-")[1]),
              };


              val.priceRange = priceRange;
              delete val.lower
              delete val.upper
              // console.log(val)
              sendRequestDotNet("Number/Search ", val).then((response) => {
                val.priceRange.lower!= 0 && formRef.current.setFieldValue("lower", parseInt(val.priceRange.lower))
                val.priceRange.upper!= 0 && formRef.current.setFieldValue("upper", parseInt(val.priceRange.upper))
                setList(response.data.list);
                setLoading(false);
              });

              // const handleClickScroll = () => {
              const element = document.getElementById("select");
              if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" });
              }
              // };
              // applyFilter(values);
            }}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="form form-label-right">
                <div className="form-group row mb-0">

                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  {
                    <div className="col-lg-6">
                      <label className="mt-2">
                        <b>
                          {intl.formatMessage({
                            id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.PREFIX",
                          })}
                        </b>
                      </label>
                      <select
                        className="form-control"
                        name="prefix"
                        placeholder="Filter by prefix"
                        onChange={(e) => {
                          setFieldValue("prefix", e.target.value);
                          // handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.prefix}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_PREFIX",
                          })}
                        </option>{" "}
                        {prefixes &&
                          prefixes.map((x, i) => (
                            <option key={i} value={x.prefix}>
                              {x.prefix}
                            </option>
                          ))}
                      </select>
                      {touched.prefix && errors.prefix ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.prefix}</div>
                        </div>
                      ) : null}
                    </div>
                  }
                  {
                    <div className="col-lg-6">
                      <label className="mt-2">
                        <b>
                          {intl.formatMessage({
                            id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.POSITION",
                          })}
                        </b>
                      </label>
                      <select
                        className="form-control"
                        placeholder="Filter by Type"
                        name="idPosition"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("idPosition", e.target.value);
                          // handleSubmit();
                        }}
                        value={values.idPosition}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id:
                              "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_POSITION",
                          })}
                        </option>
                        {positions &&
                          positions.map((x, i) => (
                            <option key={i} value={x.idParam}>
                              {x.value}
                            </option>
                          ))}
                      </select>
                      {touched.idPosition && errors.idPosition ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.idPosition}</div>
                        </div>
                      ) : null}
                    </div>
                  }
                  {
                    <div className="col-lg-6">
                      <Row>
                        <Col className="pl-0 pr-3">
                          <label className="mt-2">
                            <b>
                              {intl.formatMessage({
                                id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.FROM",
                              })}
                            </b>
                          </label>
                          <input
                            type="number"
                            className="form-control tel-input"
                            name="lower"
                            placeholder={intl.formatMessage({
                              id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.FROM",
                            })}
                            onBlur={handleBlur}
                            value={values.lower}
                            onChange={(e) => {
                              setFieldValue("lower", e.target.value);
                              // handleSubmit();
                            }}
                          />
                          {touched.lower && errors.lower ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.lower}</div>
                            </div>
                          ) : null}
                        </Col>
                        <Col className="pr-0 pl-3">
                          <label className="mt-2">
                            <b>
                              {intl.formatMessage({
                                id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.TO",
                              })}
                            </b>
                          </label>
                          <input
                            type="number"
                            className="form-control tel-input"
                            name="upper"
                            placeholder={intl.formatMessage({
                              id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.TO",
                            })}
                            onBlur={handleBlur}
                            value={values.upper}
                            onChange={(e) => {
                              setFieldValue("upper", e.target.value);
                              // handleSubmit();
                            }}
                          />
                          {touched.upper && errors.upper ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.upper}</div>
                            </div>
                          ) : null}</Col>
                      </Row>
                      {/* <small className="form-text text-muted">
                        <b>
                          {intl.formatMessage({
                            id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.PRICE_RANGE",
                          })}
                        </b>
                      </small>
                      <select
                        className="form-control"
                        placeholder="Filter by Type"
                        name="priceRange"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("priceRange", e.target.value);
                          // handleSubmit();
                        }}
                        value={values.priceRange}
                      >
                        <option value="">
                          {intl.formatMessage({
                            id:
                              "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_PRICE_RANGE",
                          })}
                        </option>
                        {priceRanges &&
                          priceRanges.map((x, i) => (
                            <option key={i} value={x.value}>
                              {x.value}
                            </option>
                          ))}
                      </select>
                      {touched.priceRange && errors.priceRange ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.priceRange}</div>
                        </div>
                      ) : null} */}
                    </div>
                  }
                  <div className="col-lg-6">
                    <label className="mt-2">
                      <b>
                        {intl.formatMessage({
                          id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.DIGITS",
                        })}
                      </b>
                    </label>
                    <input
                      type="number"
                      className="form-control tel-input"
                      name="pattern"
                      placeholder={intl.formatMessage({
                        id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.ENTER_DIGITS",
                      })}
                      onBlur={handleBlur}
                      value={values.pattern}
                      onChange={(e) => {
                        setFieldValue("pattern", e.target.value);
                        // handleSubmit();
                      }}
                    />
                    {touched.pattern && errors.pattern ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.pattern}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-12 pt-5">
                    <Button
                      onClick={() => {

                        handleSubmit();
                        setFieldValue("priceRange", values.priceRange);
                      }}
                      disabled={loading}
                      className="btn btn-warning"
                    >
                      <b>
                        {intl.formatMessage({
                          id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SEARCH",
                        })}
                      </b>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <div id="select"></div>
      <Card className="mt-5">
        <Card.Body>
          <Card.Title>
            {intl.formatMessage({
              id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_NBR",
            })}
          </Card.Title>
          <div
            style={{
              overflowY: "auto",
              height: list.length > 0 ? "500px" : "auto",
            }}
          >
            <table className="table table table-head-custom table-vertical-center overflow-hidden">
              <thead>
                <tr>
                  <th>
                    {intl.formatMessage({
                      id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.NBR",
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.PRICE",
                    })}
                  </th>
                  {/* <th>priceFloat</th>
          <th>lineType</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SkeletonTheme>
                    <tr>
                      <td>
                        <Skeleton height={30} count={3} />
                      </td>
                      <td>
                        <Skeleton height={30} count={3} />
                      </td>
                      <td>
                        <Skeleton height={30} count={3} />
                      </td>
                      {/* <td>
                  <Skeleton height={20} count={3} />
                </td> */}
                    </tr>
                  </SkeletonTheme>
                ) : list.length > 0 ? (
                  list.map((of) => (
                    <tr
                      className={
                        selectedValue === of.msisdn ? "selected-tr" : ""
                      }
                      key={`id${of.id}`}
                    >
                      <td>{of.msisdn}</td>
                      <td>{of.price}</td>
                      {/* <td>{of.priceFloat}</td> */}
                      {/* <td>{of.lineType}</td> */}
                      <td className="text-right">
                        <Radio
                          checked={selectedValue === of.msisdn}
                          onChange={() => {
                            setSelectedValue(of.msisdn);
                            let newVal = context.values;
                            newVal.basic.msisdn = of.msisdn;
                            newVal.basic.price = of.price;
                            context.setValues(newVal);
                            // setFieldValue("msisdn",of.msisdn)
                          }}
                          value={of.msisdn}
                          name="msisdn"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    {intl.formatMessage({
                      id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.NO_RECORD_AVAI",
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {selectedValue && (
            <Button className="btn btn-warning mt-5" type="submit">
              <b>
                {intl.formatMessage({
                  id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SUBMIT",
                })}
              </b>
            </Button>
          )}
          {/* <input  name="msisdn" />
        <Field
        value={selectedValue}  name="msisdn" id="msisdn"  /> */}
        </Card.Body>
      </Card>
    </>
  );
}
