import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MyBillStatusCssClasses } from "../MyBillsUIHelpers";
import * as actions from "../../../_redux/myBills/myBillsActions";
import { useMyBillsUIContext } from "../MyBillsUIContext";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { FormattedMessage } from "react-intl";

export function OpenDetailsDialog({ id,show, onHide }) {
  // MyBills UI Context
  const myBillsUIContext = useMyBillsUIContext();
  const myBillsUIProps = useMemo(() => {
    return {
      ids: myBillsUIContext.ids,
      setIds: myBillsUIContext.setIds,
      queryParams: myBillsUIContext.queryParams,
    };
  }, [myBillsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.myBills }),
    shallowEqual
  );
  const { totalCount, entities, isLoading, doc } = currentState;

  // if there weren't selected myBills we should close modal
  useEffect(() => {
    setDetails();
    // if (!id) {
    //   onHide();
    // } else {
    let params = {
      id: id
    }
    dispatch(actions.viewDetails(params)).then(
      (doc) => {
        setDetails(doc)
      }
    );
    // }
  }, []);

  const [status, setStatus] = useState(0);
  const [details, setDetails] = useState();
  const dispatch = useDispatch();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage id="BILLS.DETAILS"></FormattedMessage>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
                {
                  details && 
                  // <div dangerouslySetInnerHTML={theObj} />
                  <div>{ReactHtmlParser(decodeURIComponent(escape(atob(details.contentbase64))))}</div>
                  // <div>{atob(details.contentbase64)}</div>
                }
        <div className="text-center">
          {/* <button
            type="button"
            onClick={updateStatus}
            className="btn btn-warning btn-elevate"
            disabled={isLoading}
          >
            <b>Download Bill</b>
                {isLoading && (
                  <span className="mx-3 spinner spinner-white"></span>
                )}
          </button> */}
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="form flex-center">
        <div className="form-group">
        </div>
      </Modal.Footer> */}
    </Modal>
  );
}
