import axios from "axios";
import { sendRequestDotNet } from "../../../../../_utils/Axios";

export const CUSTOMERS_URL = "api/myBills";
export const OFFER_URL = "Account";


export function findMyBills() {
  return sendRequestDotNet(`${OFFER_URL}/HistoryPostpaidBills`, {});
}
export function payMyBill(params) {
  return sendRequestDotNet(`${OFFER_URL}/GetPostpaidWalletUrl`, params);
}
export function downloadBill(params) {
  return sendRequestDotNet(`${OFFER_URL}/DownloadBill`, params);
}

// UPDATE Status
export function updateStatusForMyBills(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForMyBills`, {
    ids,
    status
  });
}
