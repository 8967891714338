import React from "react";
import { Route } from "react-router-dom";
import { RechargeLinesLoadingDialog } from "./rechargeLines-loading-dialog/RechargeLinesLoadingDialog";
import { RechargeLinesUpdateStatusDialog } from "./rechargeLines-update-status-dialog/RechargeLinesUpdateStatusDialog";
import { RechargeLinesCard } from "./RechargeLinesCard";
import { RechargeLinesUIProvider } from "./RechargeLinesUIContext";

export function RechargeLinePage({ history }) {
  const rechargeLinesUIEvents = {
    openUpdateRechargeLinesStatusDialog: () => {
      history.push("/my-korek/recharge-line/updateStatus");
    },
  };

  return (
    <RechargeLinesUIProvider rechargeLinesUIEvents={rechargeLinesUIEvents}>
      <RechargeLinesLoadingDialog />
      <Route path="/my-korek/recharge-line/updateStatus">
        {({ history, match }) => (
          <RechargeLinesUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/my-korek/recharge-line");
            }}
          />
        )}
      </Route>
      <RechargeLinesCard />
    </RechargeLinesUIProvider>
  );
}
