import * as requestFromServer from "./myBillsCrud";
import {myBillsSlice, callTypes} from "./myBillsSlice";

const {actions} = myBillsSlice;

export const fetchMyBills = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyBills(queryParams)
    .then(response => {
      const { list } = response.data;
      dispatch(actions.myBillsFetched({ list }));
    })
    .catch(error => {
      error.clientMessage = "Can't find myBills";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateMyBillsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForMyBills(ids, status)
    .then(() => {
      dispatch(actions.myBillsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update myBills status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const payMyBill = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .payMyBill(params)
    .then((response) => {
      dispatch(actions.paidBill({}));
      return response.data.url;
    })
    .catch(error => {
      error.clientMessage = "Can't update myBills status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const viewDetails = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .downloadBill(params)
    .then((response) => {
      let doc = {
        "contentbase64": response.data.contentbase64,
        "docType": response.data.docType
      }
      dispatch(actions.downloadDetails(doc));
      return doc;
    })
    .catch(error => {
      error.clientMessage = "Can't update myBills status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};