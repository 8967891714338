import React from "react";
import {
  MyBillStatusCssClasses,
  MyBillStatusTitles
} from "../../MyBillsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  
  <span
    className={`label label-lg label-light-${
      MyBillStatusCssClasses[row.statusId]
    } label-inline`}
  >
    <b>{row.status}</b>
  </span>
);
