import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ActiveVasOfferStatusCssClasses } from "../ActiveVasOffersUIHelpers";
import * as actions from "../../../_redux/activeVasOffers/activeVasOffersActions";
import { useActiveVasOffersUIContext } from "../ActiveVasOffersUIContext";
import { Rating } from "@material-ui/lab";
import { FormattedMessage } from "react-intl";

const selectedActiveVasOffers = (entities, ids) => {
  const _activeVasOffers = [];
  ids.forEach((id) => {
    const activeVasOffer = entities.find((el) => el.id === id);
    if (activeVasOffer) {
      _activeVasOffers.push(activeVasOffer);
    }
  });
  return _activeVasOffers;
};

export function RateOfferDialog({ show, onHide }) {
  // ActiveVasOffers UI Context
  const activeVasOffersUIContext = useActiveVasOffersUIContext();
  const activeVasOffersUIProps = useMemo(() => {
    return {
      ids: activeVasOffersUIContext.ids,
      setIds: activeVasOffersUIContext.setIds,
      queryParams: activeVasOffersUIContext.queryParams,
    };
  }, [activeVasOffersUIContext]);

  // ActiveVasOffers Redux state
  const { activeVasOffers, isLoading } = useSelector(
    (state) => ({
      activeVasOffers: selectedActiveVasOffers(state.activeVasOffers.entities, activeVasOffersUIProps.ids),
      isLoading: state.activeVasOffers.actionsLoading,
    }),
    shallowEqual
  );

  // if there weren't selected activeVasOffers we should close modal
  useEffect(() => {
    if (activeVasOffersUIProps.ids || activeVasOffersUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVasOffersUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for updateing activeVasOffer by ids
    // dispatch(actions.updateActiveVasOffersStatus(activeVasOffersUIProps.ids, status)).then(
    //   () => {
    //     // refresh list after deletion
    //     dispatch(actions.fetchActiveVasOffers(activeVasOffersUIProps.queryParams)).then(
    //       () => {
    //         // clear selections list
    //         activeVasOffersUIProps.setIds([]);
    //         // closing delete modal
    //         onHide();
    //       }
    //     );
    //   }
    // );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
        <FormattedMessage id="ACTIVE_VAS_OFFERS.RATE_OFFER"></FormattedMessage>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{alignSelf:"center"}} className="overlay overlay-block cursor-default">
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
<Rating name="size-large" defaultValue={2} size="large" />
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-warning btn-elevate"
          >
            <b><FormattedMessage id="ACTIVE_VAS_OFFERS.SUBMIT_REVIEW"></FormattedMessage></b>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
