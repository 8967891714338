import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { sendRequestDotNet } from "../../../../_utils/Axios";
import { KTCookie } from "../../../../_metronic/_assets/js/components/cookie";

class Logout extends Component {
  componentDidMount() {

    let headers = {
      idusertype: "3",
    };

    // const dispatch = useDispatch();
    sendRequestDotNet("Profile/Logout", {})
    .then((rsp) => {
    KTCookie.setCookie("logout", false);    
    KTCookie.deleteCookie("token");

    // !rsp.error && rsp.data.statusCode.code==0 &&
    sendRequestDotNet("Registration/SkipLogin ", {}, headers).then(
      (response) => {
        if (!response.error && response.data.statusCode.code == 0) {
          KTCookie.setCookie("isSkipLogin", true);
          KTCookie.setCookie("idUserType", "1");
          KTCookie.setCookie("idGuest", response.data.idGuest);
        }

          KTCookie.setCookie("logout", true);
      }
    );
    });
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
