import React from "react";
import { Route } from "react-router-dom";
import { ActiveVasOffersLoadingDialog } from "./activeVasOffers-loading-dialog/ActiveVasOffersLoadingDialog";
import { ActiveVasOffersUpdateStatusDialog } from "./activeVasOffers-update-status-dialog/ActiveVasOffersUpdateStatusDialog";
import { ActiveVasOffersCard } from "./ActiveVasOffersCard";
import { ActiveVasOffersUIProvider } from "./ActiveVasOffersUIContext";
import { RateOfferDialog } from "./rate-offer/RateOfferDialog";

export function ActiveVasOffersPage({ history }) {
  const activeVasOffersUIEvents = {
    openUpdateActiveVasOffersStatusDialog: () => {
      history.push("/my-korek/active-vas-offers/updateStatus");
    },
    openRateOfferDialog: () => {
      history.push("/my-korek/active-vas-offers/rate-offer");
    },
  };

  return (
    <ActiveVasOffersUIProvider activeVasOffersUIEvents={activeVasOffersUIEvents}>
      <ActiveVasOffersLoadingDialog />
      <Route path="/my-korek/active-vas-offers/updateStatus">
        {({ history, match }) => (
          <ActiveVasOffersUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/my-korek/active-vas-offers");
            }}
          />
        )}
      </Route>
      <Route path="/my-korek/active-vas-offers/rate-offer">
        {({ history, match }) => (
          <RateOfferDialog
            show={match != null}
            onHide={() => {
              history.push("/my-korek/active-vas-offers");
            }}
          />
        )}
      </Route>
      <ActiveVasOffersCard />
    </ActiveVasOffersUIProvider>
  );
}
