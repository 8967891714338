import React, { useEffect, useState } from "react";
import {
  StatsWidget11,
  StatsWidget12,
  AdvanceTablesWidget2,
} from "../widgets";
import { sendRequestDotNet } from "../../../_utils/Axios";
import "../../../_metronic/_assets/sass/pages/dashboard/dashboard.scss";
import { Col, Image, Row } from "react-bootstrap";
import { KTCookie } from "../../_assets/js/components/cookie";
export function Demo1Dashboard() {

  const [data, setData] = useState();

  useEffect(() => {
    sendRequestDotNet("Main/Dashboard", { "isWithAccountItem": true }, {}).then((response) => {
      setData(response.data)
      KTCookie.setCookie("accountType", response.data.profile.accountType)
    })
  }, []);
  return (
    <div className="dashboard">

      <Row>
        {data &&
          data.accountItems.map((x, i) => x.idType != 5 && (
            <Col xxl={3} xl={3} lg={3} md={6} sm={6} xs={6} className="mb-5">
              <div className="account-info">

                <Row className="">
                  <Col xl={3}>
                    <Image className="" src={"/media/korek/account-" + x.idType + ".png"}></Image>
                  </Col>{" "}
                  <Col xl={9}>
                    <span className="upper-title">{x.itemName}</span>
                    <br />
                    <span className="lower-title">{x.itemValue}</span>
                  </Col>
                </Row>
              </div>

            </Col>
          ))
        }
      </Row>
      <div className="row">

        <div className="col-lg-6 col-xxl-6">
          {data && <StatsWidget11
            data={data.internetUsage}
            className="card-stretch gutter-b"
            symbolShape="circle"
            baseColor="primary"
          />}
        </div>

        <div className="col-lg-6 col-xxl-6">
          {data && <StatsWidget12
            data={data.voiceUsage}
            className="card-stretch gutter-b"
            symbolShape="circle"
            baseColor="danger"
          />}
        </div>
        <div className="col-xxl-12 order-2 order-xxl-1">

          {data && <AdvanceTablesWidget2
            data={data.offerHistory} className="card-stretch gutter-b" />}
        </div>
      </div>

    </div>
  );
}
